import { computed } from "vue"
import { eachDayOfInterval } from "date-fns"
import { sumBy } from "lodash-es"
import { formatDate } from "@/modules/common/utils/dateUtils"
import Timesheet = App.Domains.TimeSheets.Models.Timesheet
import Data = API.Data
import { useProjectStore } from "@/modules/projects/store/projectStore"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { storeToRefs } from "pinia"
import { isNationalHoliday } from "@/modules/holidays/utils/holidayUtils"

interface Props {
  from: Date
  to: Date
  timeLogs: Data<Timesheet>[]
}

export function useTimeUtils(data: Props) {
  const projectStore = useProjectStore()

  const daysOfWeek = computed(() => {
    return eachDayOfInterval({
      start: data.from,
      end: data.to,
    })
  })

  const weekdays = computed(() => {
    return daysOfWeek.value.map((day) => {
      return {
        label: formatDate(day, 'EEE'),
        value: day,
      }
    })
  })

  const totalMinutesForWeek = computed(() => {
    return sumBy(data.timeLogs, 'attributes.worked_minutes')
  })

  function getProjectName(time: Data<Timesheet>) {
    let name: string = time?.relationships?.project?.attributes?.name
    if (!name) {
      const project = projectStore.getProjectById(time?.attributes?.project_id)
      name = project?.attributes?.name ?? ''
    }
    return name
  }

  function getTaskName(time: Data<Timesheet>) {
    const { task_id, project_id } = time?.attributes
    let name: string = time?.relationships?.task?.attributes?.name
    if (!name) {
      const task = projectStore.getTaskById(project_id, task_id)
      name = task?.attributes?.name ?? ''
    }
    return name
  }

  function getTotalMinutes(timeLogs: Data<Timesheet>[]) {
    return sumBy(timeLogs, 'attributes.worked_minutes')
  }

  return {
    weekdays,
    totalMinutesForWeek,
    getProjectName,
    getTaskName,
    getTotalMinutes,
  }
}
