<template>
  <div>
    <PageTitle
      class="mb-2"
      :show-back="true"
      :loading="currentAssetLoading"
    >
      <template v-if="isEdit">
        {{ assetName }}
      </template>
      <template v-else>
        {{ $t('Add asset') }}
      </template>
    </PageTitle>
    <BaseFormCard
      :submit-text="isEdit ? $t('Update') : $t('Add')"
      :disabled="loading"
      @submit="createOrUpdateAsset"
      @cancel="onCancel"
    >
      <FormCardSection :title="$t('Asset Information')">
        <FormKit
          v-model="model.name"
          type="text"
          :label="$t('Name')"
          :placeholder="$t('Laptop')"
          outer-class="col-span-6 md:col-span-2"
          validation="required|string|length:0,255"
        />
        <FormKit
          v-model="model.employee_id"
          :label="$t('For Employee')"
          type="employeeSelect"
          :disabled="employeeId"
          outer-class="col-span-6 md:col-span-2"
          :placeholder="$t('Employee who uses this asset')"
        />
        <FormKit
          v-model="model.type"
          :label="$t('Type')"
          type="customSelect"
          outer-class="col-span-6 md:col-span-1"
          :options="assetTypeOptions()"
          :placeholder="$t('Electronics')"
          validation="required"
        />
        <FormKit
          v-model="model.status"
          :label="$t('Status')"
          type="customSelect"
          outer-class="col-span-6 md:col-span-1"
          :options="assetStatusOptions()"
          :placeholder="$t('Status')"
          validation="required"
        />
        <FormKit
          v-model="model.model"
          type="text"
          :label="$t('Model')"
          :placeholder="$t('Macbook Pro')"
          outer-class="col-span-6 md:col-span-2"
          validation="length:0,255"
        />
        <FormKit
          v-model="model.serial_number"
          type="text"
          :label="$t('Serial Number')"
          :placeholder="$t('Serial Number')"
          outer-class="col-span-6 md:col-span-2"
          validation="length:0,255"
        />
        <FormKit
          v-model="model.supplier"
          type="text"
          :label="$t('Supplier')"
          :placeholder="$t('Supplier')"
          outer-class="col-span-6 md:col-span-2"
          validation="length:0,255"
        />
      </FormCardSection>
      <FormCardSection :title="$t('Acquisition Information')">
        <FormKit
          v-model="model.acquisition_date"
          type="customDatePicker"
          :label="$t('Acquisition Date')"
          :placeholder="$t('Acquisition Date')"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model.purchase_value"
          type="number"
          :label="$t('Purchase Value')"
          :placeholder="$t('Purchase Value')"
          outer-class="col-span-6 md:col-span-2"
          validation="min:0"
        />
        <FormKit
          v-model="model.currency"
          type="currencySelect"
          :label="t('Currency')"
          :placeholder="t('EUR')"
          outer-class="col-span-6 md:col-span-1"
        />
      </FormCardSection>

      <FormCardSection :title="$t('Notes')">
        <FormKit
          v-model="model.notes"
          type="html"
          :label="$t('Notes')"
          :placeholder="$t('Notes about the asset...')"
          outer-class="col-span-6"
        />
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import { useI18n } from "vue-i18n"
import { useRoute, useRouter } from "vue-router"
import { storeToRefs } from "pinia"
import PageTitle from "@/components/common/PageTitle.vue"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import { useAssetStore } from "@/modules/assets/store/assetStore"
import { useFilterTabs } from "@/modules/common/composables/useFilterTabs"
import { useFormInit } from "@/modules/common/composables/useFormInit"
import { AssetStatus, assetStatusOptions, assetTypeOptions } from "@/modules/assets/util/assetUtils"
import { defaultCurrency } from "@/plugins/formatPrice"
import { SettingKeys } from "@/modules/auth/types/enums"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
  isEdit: Boolean,
  loading: Boolean,
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const employeeId = computed(() => route.query.employee_id as string)
const { getSaveRedirectPath } = useFilterTabs('assets')

const model = ref({
  name: '',
  notes: '',
  model: '',
  status: AssetStatus.Available,
  serial_number: '',
  supplier: '',
  acquisition_date: null as string | null,
  employee_id: employeeId.value as string as string | null,
  purchase_value: null as number | null,
  assigned_by: null as string | null,
  assigned_at: null as string | null,
  currency: defaultCurrency as string | null,
  type: null,
})

const settingsStore = useSettingsStore()
const defaultCompanyCurrency = computed(() => settingsStore.getSetting(SettingKeys.DefaultCurrency))

watch(() => defaultCompanyCurrency.value, (currency: string) => {
  model.value.currency = currency
})

const loading = ref(false)

const assetStore = useAssetStore()
const { assetName, currentAssetLoading } = storeToRefs(assetStore)

async function createOrUpdateAsset() {
  try {
    loading.value = true
    if (model.value.status === AssetStatus.Available && model.value.employee_id) {
      model.value.status = AssetStatus.InUse
    }
    if (props.isEdit) {
      await assetStore.updateAsset(props.data.id, model.value)
    } else {
      await assetStore.createAsset(model.value)
    }
    await router.push(getSaveRedirectPath())
  } catch (err: any) {
    if (err.handled) {
      return
    }
  } finally {
    loading.value = false
  }
}

const employeeStore = useEmployeeStore()
const defaultEmployee = computed(() => employeeStore.getEmployeeById(employeeId.value))

useFormInit(props, model)

async function onCancel() {
  await router.push(getSaveRedirectPath())
}
</script>
