<template>
  <div>
    <PageTitle
      class="mb-2"
      :show-back="true"
      :loading="currentExpenseLoading"
    >
      <template v-if="isEdit">
        {{ expenseName }}
      </template>
      <template v-else>
        {{ $t('Create expense bundle') }}
      </template>
    </PageTitle>
    <BaseFormCard
      :submit-text="isEdit ? $t('Update') : $t('Create')"
      :disabled="loading"
      @submit="onSubmit"
      @cancel="onCancel"
    >
      <FormCardSection v-if="!isEdit" :title="$t('How would you like to create the expense bundle?')">
        <ExpenseCreateRadioGroup v-model="mode" class="col-span-6" />
      </FormCardSection>
      <template v-if="mode?.value === CreateMode.Import">
        <FormCardSection :title="$t('Upload Files')">
          <ExpenseFileDropzone
            v-if="!loading"
            v-model="fromFilesModel.files"
            class="mb-9 mt-2 col-span-6"
          />
          <ExpenseProcessingAnimation v-if="loading" class="col-span-6" />
        </FormCardSection>
      </template>
      <template v-if="mode?.value === CreateMode.Manual || isEdit">
        <FormCardSection :title="$t('Bundle Information')">
          <FormKit
            v-model="model.employee_id"
            :label="$t('Employee')"
            :placeholder="$t('Employee')"
            :help="$t('Employee who the expenses belong to.')"
            type="employeeSelect"
            validation="required"
            outer-class="col-span-6 md:col-span-2"
          />
          <FormKit
            v-model="model.title"
            type="text"
            :label="$t('Title')"
            :placeholder="$t('Travel expense from Web Summit')"
            validation="required"
            outer-class="col-span-6 md:col-span-4"
          />
          <FormKit
            key="start_date"
            v-model="model.start_date"
            type="customDatePicker"
            :label="$t('Start Date')"
            :placeholder="$t('21.05.2024')"
            outer-class="col-span-6 md:col-span-2"
          />
          <FormKit
            key="end_date"
            v-model="model.end_date"
            type="customDatePicker"
            :label="$t('End Date')"
            :placeholder="'27.05.2024'"
            outer-class="col-span-6 md:col-span-2"
          />
          <div class="col-span-6" />
          <FormKit
            v-model="model.project_id"
            :label="$t('Project')"
            :placeholder="$t('Project')"
            :help="$t('Project the expenses belong to.')"
            type="projectSelect"
            outer-class="col-span-6 md:col-span-2"
          />
          <FormKit
            v-model="model.requested_approver_id"
            :label="$t('Approver')"
            :placeholder="$t('Expense approver')"
            :fetch-at-start="true"
            :url-params="{
              permissions: 'manageExpenses',
            }"
            :help="$t('Select a specific approver to send notifications to.')"
            url="/restify/employees"
            type="employeeSelect"
            outer-class="col-span-6 md:col-span-2"
          />
          <FormKit
            v-model="model.description"
            type="textarea"
            :label="$t('Notes')"
            :placeholder="$t('Add notes to the expense bundle')"
            outer-class="col-span-6"
          />
        </FormCardSection>
        <FormCardSection :title="$t('Expenses')" container-class="max-w-7xl">
          <ExpenseTable
            ref="expenseTable"
            :no-url="!isEdit"
            :expenses="mappedExpenses"
            :bundle-id="model?.id"
            :project-id="model.project_id"
            :editable="true"
            class="col-span-6"
          >
            <template #actions-before>
              <UploadFilesToBundle
                v-if="isEdit"
                :bundle-id="model.id"
                @save="$refs.expenseTable?.refresh()"/>
            </template>
          </ExpenseTable>
        </FormCardSection>
      </template>
    </BaseFormCard>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue"
import { storeToRefs } from "pinia"
import { useRouter } from "vue-router"
import { useI18n } from "vue-i18n"
import PageTitle from "@/components/common/PageTitle.vue"
import { CreateMode, useExpenseStore } from "@/modules/expenses/store/expenseStore"
import { useFilterTabs } from "@/modules/common/composables/useFilterTabs"
import ExpenseProcessingAnimation from "@/modules/expenses/components/ExpenseProcessingAnimation.vue"
import ExpenseCreateRadioGroup from "@/modules/expenses/components/ExpenseCreateRadioGroup.vue"
import { error } from "@/components/common/NotificationPlugin"
import { useFormInit } from "@/modules/common/composables/useFormInit"
import ExpenseTable from "@/modules/expenses/components/ExpenseTable.vue"
import { useAuth } from "@/modules/auth/composables/useAuth"
import UploadFilesToBundle from "@/modules/expenses/components/UploadFilesToBundle.vue"
import ExpenseFileDropzone from "@/modules/expenses/components/ExpenseFileDropzone.vue"

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
  isEdit: {
    type: Boolean,
  },
  loading: Boolean,
})

const expenseStore = useExpenseStore()
const { expenseName, currentExpense, currentExpenseLoading } = storeToRefs(expenseStore)

const isEdit = computed<boolean>(() => {
  return props.data?.id !== undefined
})

const { t } = useI18n()
const router = useRouter()
const loading = ref(false)
const mode = ref({
  value: null,
})

const { userEmployeeId } = useAuth()

const model = ref({
  title: '',
  description: '',
  start_date: '',
  end_date: '',
  employee_id: userEmployeeId.value || '',
  project_id: '',
  requested_approver_id: '',
  expenses: [],
})

const fromFilesModel = ref({
  files: [],
})

useFormInit(props, model)

const mappedExpenses = computed(() => {
  return model.value.expenses.map((expense: any) => {
    return {
      id: expense.id,
      ...(expense.attributes || {}),
    }
  })
})

const { getSaveRedirectPath } = useFilterTabs('expenses')

function onSubmit() {
  if (mode.value?.value === CreateMode.Import) {
    createFromFiles()
  } else {
    createOrUpdateBundle()
  }
}

async function createFromFiles() {
  try {
    loading.value = true
    const res: any = await expenseStore.createExpenseFromFiles(fromFilesModel.value)
    await router.push(`/expenses/${res.id}/edit`)
  } catch (err: any) {
    if (err?.handled) {
      return
    }
    error(t('Could not create expense bundle from files'))
  } finally {
    loading.value = false
  }
}

function getData() {
  return {
    ...model.value,
    expenses: undefined,
  }
}

async function createOrUpdateBundle() {
  try {
    loading.value = true
    const requestData = getData()
    if (isEdit.value) {
      await expenseStore.updateExpense(requestData)
    } else {
      await expenseStore.createExpense(requestData)
    }
    await router.push(getSaveRedirectPath())
  } catch (err: any) {
    if (err.handled) {
      return
    }
  } finally {
    loading.value = false
  }
}

async function onCancel(event: Event) {
  if (event?.target?.nodeName === 'INPUT') {
    event.preventDefault()
    return
  }
  await router.push(getSaveRedirectPath())
}
</script>

<style>
</style>
