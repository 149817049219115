<template>
  <BaseContentCard
    v-if="showCard"
    class="featured-news relative overflow-hidden !pb-0"
  >
    <BackgroundBubble class="absolute z-0 left-[-90px] top-[-217px] rotate-[16deg] h-[390px] w-auto" />
    <div class="flex items-center space-x-5 mb-5">
      <IconBox size="sm">
        <CakeIcon class="w-4 h-4" />
      </IconBox>
      <h3>{{ $t('Birthday Alert') }}</h3>
    </div>
    <div class="prose max-w-5xl pt-2 pb-6">
      <p v-if="hasBirthdaysToday" class="font-semibold">
        {{ $t('🎉 Ding ding! One (or more) of our colleagues is celebrating today! 🥳') }}
      </p>
      <p v-if="hasBirthdaysTomorrow" class="font-semibold">
        {{ $t('🎉 Ding ding! One (or more) of our colleagues are celebrating tomorrow! 🥳') }}
      </p>
      <p>
        {{ birthDayMessage }}
      </p>
    </div>
  </BaseContentCard>
</template>

<script setup>
import { CakeIcon } from "@heroicons/vue/24/outline"
import { addMinutes, isSameDay, parseISO, startOfTomorrow } from "date-fns"
import { storeToRefs } from "pinia"
import { computed } from "vue"
import { useI18n } from "vue-i18n"
import BackgroundBubble from "@/modules/dashboard/components/news/BackgroundBubble.vue"
import { useDashboardStore } from "@/modules/dashboard/store/dashboardStore"
import { useAuth } from "@/modules/auth/composables/useAuth"

const dashboardStore = useDashboardStore()
const { dashboardData } = storeToRefs(dashboardStore)

const birthdayDates = computed(() => {
  const year = new Date().getFullYear()
  return dashboardData.value.birthdays.map((birthday) => {
    let start = parseISO(birthday.birth_date)
    start.setFullYear(year)
    start = addMinutes(start, -start.getTimezoneOffset())
    return start
  })
})

const { t } = useI18n()

const messages = computed(() => {
  return [
    t('Act natural, like you totally remembered! Drop some birthday wishes and casually scope out the cake situation. 🎂😉'),

    t('It’s birthday time! Say something nice, look excited, and maybe ‘accidentally’ end up near the cake. 🍰'),

    t('Quick! Wish them a happy birthday before they realize you needed this reminder. Bonus points if you secure some cake. 🎂🎉'),

    t('Go on, make their day—send some cheerful wishes! And while you’re at it, keep an eye out for cake opportunities. 🍰👀'),

    t(`It’s celebration time! Hand out some birthday wishes, flash a big smile, and position yourself wisely for cake distribution. 🎂😏`),
  ]
})

const birthDayMessage = computed(() => {
  const today = new Date()
  const index = today.getDate() % messages.value.length // Ensures the message stays the same for the day
  return messages.value[index]
})

const hasBirthdaysToday = computed(() => {
  const dayToCompare = new Date()
  return birthdayDates.value.some((birthday) => {
    return isSameDay(birthday, dayToCompare)
  })
})

const hasBirthdaysTomorrow = computed(() => {
  const dayToCompare = startOfTomorrow()
  return birthdayDates.value.some((birthday) => {
    return isSameDay(birthday, dayToCompare)
  })
})

const { isEmployee } = useAuth()

const showCard = computed(() => {
  if (isEmployee.value) {
    return false
  }
  return hasBirthdaysToday.value || hasBirthdaysTomorrow.value
})
</script>

<style>
</style>
