<template>
  <AssetForm />
</template>

<script setup lang="ts">
import { useHead } from "@vueuse/head"
import { useI18n } from "vue-i18n"
import AssetForm from "@/modules/assets/components/AssetForm.vue"
const { t } = useI18n()

useHead({
  title: t('Add asset'),
})
</script>

<route lang="yaml">
name: Add asset
</route>
