<template>
  <div class="flex justify-between px-2.5">
    <div class="hidden md:block text-sm font-semibold">
      {{ $t('Actions') }}
    </div>
    <ElDropdown
      :hide-on-click="false"
      trigger="click"
      placement="bottom-end"
      @visible-change="onDropdownToggle"
    >
      <div tabindex="0" class="ml-1">
        <ViewColumnsIcon class="w-5 h-5" />
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <li class="px-2 pt-1 pb-2 border-b border-gray-200 mb-1">
            <BaseCheckbox
              :checked="allColumnsChecked"
              class="checkbox-sm"
              @change="setAllColumnsVisible"
            >
              {{ $t('Select All') }}
            </BaseCheckbox>
          </li>
          <div class="max-h-[400px] overflow-auto">
            <li
              v-for="column in getToggleColumns()"
              :key="column.getColId()"
              class="px-2 py-1"
            >
              <BaseCheckbox
                :checked="column.visible"
                class="checkbox-sm"
                @change="setColumnVisible(column, $event)"
              >
                {{ column.getColDef()?.headerName }}
              </BaseCheckbox>
            </li>
          </div>
        </el-dropdown-menu>
      </template>
    </ElDropdown>
  </div>
</template>

<script setup lang="ts">
import { ViewColumnsIcon } from '@heroicons/vue/24/outline'
import { ColDef, Column } from '@ag-grid-community/core'
import { useStorage } from '@vueuse/core'
import { computed } from 'vue'

const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  },
})

const storageColumns = useStorage(props.params.context.storageKey, {})

const allColumnsChecked = computed(() => {
  return getToggleColumns().every((col: Column) => col.visible)
})

function getToggleColumns(): Column[] {
  const allColumns: Column[] = props.params.columnApi.getColumns()
  return allColumns.filter((col) => {
    const colDef = col.getColDef()
    return !colDef.lockVisible && !colDef.initialHide
  })
}

function setColumnVisible(col: Column, event: any) {
  const value = event.target?.checked
  props.params.columnApi.setColumnVisible(col, value)
}

function saveColumns() {
  const colDefs: ColDef[] = props.params.api.getColumnDefs()
  storageColumns.value = colDefs
}

function setAllColumnsVisible(event: any) {
  const value = event.target?.checked
  const cols = getToggleColumns()
  cols.forEach((col: Column, index: number) => {
    if (!value && index === 0) {
      return
    }
    props.params.columnApi.setColumnVisible(col, value)
  })
}

function onDropdownToggle(visible: boolean) {
  if (visible) {
    return
  }
  saveColumns()
}
</script>

<style lang="scss">
.actions-header {
  .ag-cell-wrapper {
    @apply w-full;
  }
}
</style>
