<template>
  <div class="px-4 pt-4 sm:px-10 sm:pt-12 sm:pb-6 h-full flex flex-col relative overflow-hidden bg-white sm:rounded-lg employee-onboarding">
    <div>
      <LogoLandscape />
    </div>
    <div class="mt-6 md:mt-20 flex items-center justify-between max-w-4xl">
      <DottedStepper
        :steps="steps"
        :current-step-index="currentStepIndex"
      />
    </div>
    <div class="mt-4 flex items-center space-x-2">
      <h2 class="text-gray-900 text-3xl sm:text-4xl font-extrabold">
        {{ currentStep.name }}
      </h2>
    </div>
    <p class="text-gray-400 leading-7 text-sm mt-1">
      <span class="flex items-center">
        <InformationCircleIcon class="hidden sm:block text-primary w-4 h-4 mr-1" />
        <span>{{ currentStep.tip }}</span>
      </span>
    </p>
    <BackgroundBubble class="absolute z-0 right-[-90px] top-[-217px] rotate-[16deg] h-[390px] w-auto" />
    <BackgroundBubble
      class="hidden md:absolute z-0 right-[-90px] bottom-[-217px] opacity-0 md:opacity-100 rotate-[16deg] h-[390px] w-auto"
    />
    <div ref="scrollArea" class="max-w-4xl px-2 w-full flex-1 flex flex-col overflow-y-auto z-100">
      <div v-if="dataLoading" class="h-full flex space-x-2 items-center justify-center">
        <LoadingTable />
      </div>
      <EmployeeOnboardingForm
        v-else
        :step="currentStepIndex"
        :employee="currentEmployee"
        :loading="dataLoading"
        :show-back="currentStepIndex > 0"
        :next-text="currentStepIndex < steps.length - 1 ? t('Next') : t('All Set!')"
        @back="goBack"
        @next="goNext"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from "vue"
import { useI18n } from "vue-i18n"
import { storeToRefs } from "pinia"
import { InformationCircleIcon } from "@heroicons/vue/24/outline"
import DottedStepper from "@/modules/employees/components/DottedStepper.vue"
import BackgroundBubble from "@/modules/dashboard/components/news/BackgroundBubble.vue"
import EmployeeOnboardingForm from "@/modules/employees/components/EmployeeOnboardingForm.vue"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import { useAuthStore } from "@/modules/auth/store/authStore"
import LoadingTable from "@/components/table/LoadingTable.vue"
import router from "@/router/router"

const { t } = useI18n()
const currentStepIndex = ref(0)
const steps = computed(() => {
  return [
    {
      name: t('Meet You 👋'),
      tip: t('The more you share, the more your colleagues will know you\'re not just an email address! 😉'),
    },
    {
      name: t('Where in the World? 🌎'),
      tip: t('Let people know where to send snacks… or at least where you’re working from!'),
    },
    {
      name: t('Show Me the Money 💰'),
      tip: t('Complete this now = fewer HR emails later. Win-win!'),
    },
  ]
})

const scrollArea = ref()

const currentStep = computed(() => {
  return steps.value[currentStepIndex.value] || {}
})

async function goBack() {
  if (currentStepIndex.value <= 0) {
    return
  }
  currentStepIndex.value--
  await scrollToTop()
}

async function goNext() {
  if (currentStepIndex.value >= steps.value.length) {
    return
  }
  currentStepIndex.value++
  if (currentStepIndex.value === steps.value.length) {
    await router.push('/dashboard?welcome=true')
  } else {
    await scrollToTop()
  }
}

async function scrollToTop() {
  await nextTick()
  scrollArea.value?.scrollTo(0, 0, {
    behavior: 'smooth',
  })
}

const employeeStore = useEmployeeStore()
const { currentEmployee } = storeToRefs(employeeStore)

const dataLoading = ref(true)
const authStore = useAuthStore()

async function loadData() {
  try {
    dataLoading.value = true
    await authStore.getProfile()
    await employeeStore.getOwnEmployee()
  } finally {
    dataLoading.value = false
  }
}

onMounted(async () => {
  await loadData()
})
</script>

<style>
.employee-onboarding {
  form {
    @apply flex-1 flex flex-col;
  }
}
</style>

<route lang="yaml">
name: Personal Onboarding
meta:
  layout: employeeOnboardingLayout
</route>
