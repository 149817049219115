<template>
  <TimezoneSelect
    ref="select"
    v-model="value"
    v-focus
    class="w-full"
    @update:modelValue="onChangeValue"
  />
</template>

<script>
import { useCellEditor } from "@/components/table/cells/editors/useCellEditor"
import TimezoneSelect from "@/components/formkit/TimeZoneSelect.vue"

export default {
  components: {
    TimezoneSelect,
  },
  setup(props) {
    return useCellEditor(props.params)
  },
}
</script>
