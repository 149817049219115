<template>
  <div class="flex flex-col">
    <PageTitle>
      {{ $t('Bulk update members') }} {{ $t('(Beta)') }}
    </PageTitle>
    <BaseFormDialog
      v-if="showExportDialog"
      v-model="showExportDialog"
      :title="t(`Export employees`, employees.length)"
      :submit-text="$t('Export employees', employees.length)"
      @submit="exportEmployees"
      @cancel="showExportDialog = false"
    >
      <div class="col-span-6 text-center">
        <p
          class="text-sm leading-5 text-base-300"
          v-html="$t('Export employees information tip')"
        />
      </div>
      <div class="col-span-6">
        <ElCheckbox
          v-model="selectAllColumnsForExport"
          @change="onSelectAllChanged"
        >
          {{ $t('Select all columns') }}
        </ElCheckbox>
        <ElCheckboxGroup v-model="selectedColumnsToExport" class="flex flex-col space-y-3 mt-3">
          <div v-for="(column, index) in columns" :key="index" class="flex flex-col">
            <div class="text-gray-700 h-4 text-sm font-medium mb-1">
              {{ column.headerName }}
            </div>
            <ElCheckbox
              v-for="child in column.children"
              :key="child?.field"
              :label="child.field"
              :name="child.field"
            >
              {{ child.headerName }}
            </ElCheckbox>
          </div>
        </ElCheckboxGroup>
      </div>
    </BaseFormDialog>
    <BaseDataTable
      ref="table"
      url="/restify/employees"
      :url-params="urlParams"
      :columns="columns"
      :add-text="$t('Add Member')"
      :row-height="40"
      :actions="$can('manageEmployees') ? 'view,edit' : ''"
      :editable="isEditable"
      :process-cell-for-clipboard="processCellForClipboard"
      v-bind="editableTableProps"
      dom-layout="normal"
      entity="employees"
      class="flex-1"
      :transform-data="transformData"
      :group-include-total-footer="true"
      @grid-init="grid = $event"
      @cell-value-changed="onCellValueChanged"
    >
      <template
        v-if="can('manageEmployees')"
        #bulk-actions
      >
        <li>
          <button
            type="button"
            class="btn-sm no-underline"
            @click="toggleExportDialog"
          >
            <DocumentArrowDownIcon class="w-4 h-4 text-primary" />
            <span class="text-left">{{ t('Export employees', employees.length) }}</span>
          </button>
        </li>
        <li>
          <button
            type="button"
            class="btn-sm no-underline"
            @click="exportBankInformation"
          >
            <DocumentArrowDownIcon class="w-4 h-4 text-primary" />
            <span class="text-left">{{ t('Export bank information') }}</span>
          </button>
        </li>
      </template>
      <template #after-refresh>
        <HiddenDetailsToggleButton />
      </template>
      <template #attributes.manager_employee_id="{ row, params }">
        <EmployeeLink
          v-if="isNormalRow(params)"
          :params="{ value: row.attributes.manager_employee_id }"
          :show-link="false"/>
      </template>
      <template #attributes.first_name="{ row, params }">
        <div v-if="isNormalRow(params)" class="flex items-center">
          <Status
            :params="{
              value: row?.attributes?.status,
            }"
          >
            <div class="flex items-center space-x-2">
              <EmployeeAvatar
                :data="row"
                :show-position="false"
                :show-name="false"
              />
              <span>
                {{ row.attributes.first_name }}
              </span>
            </div>
          </Status>
        </div>
      </template>
      <template #attributes.salary="{ row, params }">
        <HiddenDetails v-if="isNormalRow(params)" show-button-on-hover>
          <EmployeeSalaryField
            :amount="row.attributes?.salary"
            :type="row.attributes?.salary_type"
            :currency="row.attributes?.salary_currency"
          />
        </HiddenDetails>
      </template>
      <template #attributes.estimated_monthly_salary="{ row, params }">
        <HiddenDetails v-if="isNormalRow(params)" show-button-on-hover>
          <span>{{ $formatPrice(getEstimatedMonthlySalary(row), { currency: row.attributes?.salary_currency }) }}</span>
        </HiddenDetails>
        <HiddenDetails v-else show-button-on-hover>
          <span>{{ $formatPrice(params.value, { currency: defaultCurrency }) }}</span>
        </HiddenDetails>
      </template>
    </BaseDataTable>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref } from "vue"
import { capitalize } from "lodash-es"
import { useI18n } from "vue-i18n"
import { set } from "lodash"
import { storeToRefs } from "pinia"
import { DocumentArrowDownIcon } from "@heroicons/vue/24/outline"
import get from "lodash/get"
import {
  CellValueChangedEvent,
  GridReadyEvent,
  ProcessCellForExportParams,
  ValueFormatterParams, ValueGetterParams,
  ValueSetterParams,
} from "@ag-grid-community/core"
import { ColumnTypes, tableCellEditors } from "@/components/table/cells/tableCellComponents"
import {
  getContractTypeLabel,
  getEmployeeNameById,
  salaryTypeOptions,
  SalaryTypes
} from "@/modules/clients/utils/employeeUtils"
import { can } from "@/plugins/permissionPlugin"
import {
  emailValueSetter,
  positiveNumericValueSetter,
  requiredMaxLengthValueSetter,
  updateInlineEntry,
} from "@/components/table/tableUtils"
import BaseDataTable from "@/components/table/BaseDataTable.vue"
import { error } from "@/components/common/NotificationPlugin"
import { useTableEditing } from "@/components/table/useTableEditing"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import EmployeeSalaryField from "@/modules/employees/components/EmployeeSalaryField.vue"
import HiddenDetails from "@/components/common/HiddenDetails.vue"
import HiddenDetailsToggleButton from "@/components/common/HiddenDetailsToggleButton.vue"
import { SettingKeys } from "@/modules/auth/types/enums"
import { formatPrice, getCurrencySymbol } from "@/plugins/formatPrice"
import Status from "@/components/table/cells/Status.vue"
import { API_DATE_FORMAT, CalendarDateFormat, EXCEL_DATE_FORMAT, formatDate } from "@/modules/common/utils/dateUtils"
import { $confirm } from "@/components/common/modal/modalPlugin"
import EmployeeLink from "@/components/table/cells/EmployeeLink.vue"
import { FilterTypes } from "@/components/table/filters/filterTypes"
import Data = API.Data
import Employee = App.Domains.Employees.Models.Employee
import EmployeeAvatar from "@/modules/employees/components/EmployeeAvatar.vue"
import { getGenderOptions } from "@/modules/employees/utils/employeeUtils";

const { t } = useI18n()
const grid = ref<GridReadyEvent>()

const isEditable = computed(() => {
  return can('manageEmployees')
})

const { editableTableProps } = useTableEditing(grid)
const settingsStore = useSettingsStore()
const { departments, positions, levels, roles } = storeToRefs(settingsStore)

const defaultCurrency = computed(() => settingsStore.getSetting(SettingKeys.DefaultCurrency))
const defaultCurrencySymbol = computed(() => {
  return getCurrencySymbol(defaultCurrency.value)
})

function hourlyCurrencyFormatter(params: ValueFormatterParams) {
  if (!isNormalRow(params)) {
    return
  }
  const value = formatPrice(params.value, {
    currency: defaultCurrency.value,
  })
  if (!params.value) {
    return value
  }
  return `${value} / ${t('hour')}`
}

function dateFormatter(params: ValueFormatterParams) {
  if (!params.value || !isNormalRow(params)) {
    return ''
  }
  return formatDate(params.value, EXCEL_DATE_FORMAT)
}

function isNormalRow(params: any) {
  return !params.node.group && !params.node.footer
}

const nameMaxLength = 100

const columns = ref([
  {
    headerName: t('General Information'),
    children: [
      {
        headerName: t('First Name'),
        field: 'attributes.first_name',
        type: 'custom',
        minWidth: 120,
        editable: isEditable.value,
        pinned: 'left',
        valueSetter: (params: ValueSetterParams) => requiredMaxLengthValueSetter(params, nameMaxLength),
        filter: FilterTypes.EmployeeStatus,
      },
      {
        headerName: t('Last Name'),
        field: 'attributes.last_name',
        minWidth: 180,
        editable: isEditable.value,
        valueSetter: (params: ValueSetterParams) => requiredMaxLengthValueSetter(params, nameMaxLength),
      },
      {
        headerName: t('Personal Email'),
        field: 'attributes.personal_email',
        minWidth: 240,
        editable: isEditable.value,
        valueSetter: emailValueSetter,
      },
      {
        headerName: t('Gender'),
        field: 'attributes.gender',
        minWidth: 150,
        editable: isEditable.value,
        cellEditor: tableCellEditors.BaseSelectEditor,
        cellEditorParams: {
          options: getGenderOptions(),
        },
        valueFormatter: (params: ValueFormatterParams) => {
          return getGenderOptions().find((option: any) => option.value === params.value)?.label || ''
        },
      },
      {
        headerName: t('Date of Birth'),
        field: 'attributes.birth_date',
        minWidth: 150,
        editable: isEditable.value,
        cellEditor: 'agDateStringCellEditor',
        type: ColumnTypes.Date,
        valueFormatter: dateFormatter,
      },
      {
        headerName: t('Email'),
        field: 'attributes.email',
        minWidth: 300,
        editable: false,
        cellClass: 'bg-gray-100',
        tooltipValueGetter: () => {
          return t('Email can not be changed in bulk update')
        },
      },
      {
        headerName: t('Role'),
        field: 'attributes.role_id',
        minWidth: 150,
        editable: false,
        cellClass: 'bg-gray-100',
        valueFormatter: (params: ValueFormatterParams) => {
          const role = roles.value.find((role: any) => role.id === params.value)?.attributes?.name || ''
          return capitalize(role)
        },
        tooltipValueGetter: () => {
          return t('Role can not be changed in bulk update') as string
        },
      },
    ],
  },
  {
    headerName: t('Position & Start Date'),
    children: [
      {
        headerName: t('Level'),
        field: 'attributes.level_id',
        minWidth: 150,
        editable: isEditable.value,
        cellEditor: tableCellEditors.LevelCellEditor,
        filter: FilterTypes.Level,
        useValueFormatterForExport: true,
        valueFormatter: (params: ValueFormatterParams) => {
          const level = levels.value.find((level: any) => level.id === params.value)?.attributes?.name || ''
          return capitalize(level)
        },
      },
      {
        headerName: t('Position'),
        field: 'attributes.position_id',
        valueFormatter: (params: ValueFormatterParams) => {
          return positions.value.find((position: any) => position.id === params.value)?.attributes?.name || ''
        },
        minWidth: 180,
        editable: isEditable.value,
        cellEditor: tableCellEditors.PositionCellEditor,
        filter: FilterTypes.Position,
      },
      {
        headerName: t('Position Code'),
        field: 'attributes.position_code',
        minWidth: 120,
        editable: isEditable.value,
      },
      {
        headerName: t('Department'),
        field: 'relationships.position.attributes.department_id',
        minWidth: 180,
        editable: false,
        cellEditor: tableCellEditors.PositionCellEditor,
        cellClass: 'bg-gray-100 capitalize',
        tooltipValueGetter: () => {
          return t('Department can not be changed in bulk update') as string
        },
        valueFormatter: (params: ValueFormatterParams) => {
          const position = positions.value.find((position: any) => position.id === params.data?.attributes?.position_id)
          const department_id = position?.attributes?.department_id
          return departments.value.find((department: any) => department.id === department_id)?.attributes?.name || ''
        },
        useValueFormatterForExport: true,
        filter: FilterTypes.Department,
      },
      {
        headerName: t('Start Date'),
        field: 'attributes.start_date',
        minWidth: 140,
        type: ColumnTypes.Date,
        editable: isEditable.value,
        cellEditor: 'agDateStringCellEditor',
        filter: FilterTypes.StartDate,
        valueFormatter: dateFormatter,
      },
      {
        headerName: t('Direct Manager'),
        field: 'attributes.manager_employee_id',
        type: 'custom',
        minWidth: 180,
        useValueFormatterForExport: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return getEmployeeNameById(params.value)
        },
        editable: isEditable.value,
        cellEditor: tableCellEditors.EmployeeCellEditor,
      },
    ],
  },
  {
    headerName: t('Address'),
    children: [
      {
        headerName: t('Street'),
        field: 'attributes.address.street',
        minWidth: 180,
        editable: isEditable.value,
      },
      {
        headerName: t('City'),
        field: 'attributes.address.city',
        minWidth: 180,
        editable: isEditable.value,
      },
      {
        headerName: t('State'),
        field: 'attributes.address.state',
        minWidth: 180,
        editable: isEditable.value,
      },
      {
        headerName: t('Country'),
        field: 'attributes.address.country',
        minWidth: 180,
        editable: isEditable.value,
        cellEditor: tableCellEditors.CountryCellEditor,
      },
      {
        headerName: t('Timezone'),
        field: 'attributes.timezone',
        minWidth: 180,
        editable: isEditable.value,
        cellEditor: tableCellEditors.TimezoneCellEditor,
      },
    ],
  },
  {
    headerName: t('Personal Details'),
    children: [
      {
        headerName: t('Personal Identification Number'),
        field: 'attributes.personal_identification_number',
        minWidth: 220,
        editable: isEditable.value,
        type: ColumnTypes.HiddenValue,
      },
      {
        headerName: t('Identity Card Number'),
        field: 'attributes.identity_card_number',
        minWidth: 220,
        editable: isEditable.value,
        type: ColumnTypes.HiddenValue,
      },
    ],
  },
  {
    headerName: t('Salary'),
    children: [
      {
        headerName: t('Contract Type'),
        field: 'attributes.contract_type',
        minWidth: 160,
        editable: false,
        cellClass: 'bg-gray-100',
        tooltipValueGetter: () => {
          return t('Contract type can not be changed in bulk update')
        },
        valueFormatter: (params: ValueFormatterParams) => {
          return getContractTypeLabel(params.value)
        },
        filter: FilterTypes.ContractType,
      },
      {
        headerName: t('Salary Type'),
        field: 'attributes.salary_type',
        minWidth: 100,
        editable: isEditable.value,
        cellEditor: tableCellEditors.BaseSelectEditor,
        cellEditorParams: {
          options: salaryTypeOptions,
        },
        valueFormatter: (params: ValueFormatterParams) => {
          return salaryTypeOptions.find((option: any) => option.value === params.value)?.label || ''
        },
      },
      {
        headerName: t('Salary'),
        field: 'attributes.salary',
        minWidth: 220,
        type: 'custom',
        editable: isEditable.value,
        cellEditor: 'agNumericCellEditor',
        valueSetter: positiveNumericValueSetter,
      },
      {
        headerName: t('Currency'),
        field: 'attributes.salary_currency',
        minWidth: 100,
        editable: isEditable.value,
        cellEditor: tableCellEditors.CurrencySelectEditor,
      },
      {
        headerName: t('Estimated Monthly Salary'),
        field: 'attributes.estimated_monthly_salary',
        type: 'custom',
        minWidth: 240,
        editable: isEditable.value,
        cellClass: 'bg-gray-100',
        valueGetter: (params: ValueGetterParams) => {
          return getEstimatedMonthlySalary(params.data)
        },
        aggFunc: 'sum',
      },
    ],
  },
  {
    headerName: t('Bank details'),
    children: [
      {
        headerName: t('Bank'),
        field: 'attributes.contract_bank_name',
        minWidth: 150,
        editable: isEditable.value,
      },
      {
        headerName: t('Swift Code'),
        field: 'attributes.contract_bank_swift',
        minWidth: 140,
        editable: isEditable.value,
      },
      {
        headerName: t('IBAN'),
        field: 'attributes.contract_bank_account',
        minWidth: 250,
        type: ColumnTypes.HiddenValue,
        editable: isEditable.value,
      },
    ],
  },
  {
    headerName: t('Capacity & Costs'),
    children: [
      {
        headerName: t('Weekly capacity'),
        field: 'attributes.weekly_hours_capacity',
        minWidth: 150,
        editable: isEditable.value,
        valueFormatter: (params: ValueFormatterParams) => {
          return `${params.value} ${t('hours')}`
        },
        aggFunc: 'sum',
        valueSetter: positiveNumericValueSetter,
      },
      {
        headerName: `${t('Default billable rate')} (${defaultCurrencySymbol.value})`,
        field: 'attributes.billed_rate',
        type: ColumnTypes.HiddenValue,
        minWidth: 150,
        initialHide: !can('manageInvoices'),
        editable: isEditable.value,
        cellEditor: 'agNumericCellEditor',
        valueFormatter: hourlyCurrencyFormatter,
        valueSetter: positiveNumericValueSetter,
        useValueFormatterForExport: true,
      },
      {
        headerName: `${t('Internal cost rate')} (${defaultCurrencySymbol.value})`,
        field: 'attributes.internal_cost_hourly_rate',
        type: ColumnTypes.HiddenValue,
        minWidth: 150,
        initialHide: !can('manageInvoices'),
        editable: isEditable.value,
        cellEditor: 'agNumericCellEditor',
        valueFormatter: hourlyCurrencyFormatter,
        valueSetter: positiveNumericValueSetter,
        useValueFormatterForExport: true,
      },
    ],
  },
])

const urlParams = computed(() => {
  return {
    related: 'user.invitations,user.roles,position',
    archived: false,
  }
})

function isRowValid(row: any) {
  return true
}

function getRowData(entry: Data<Employee>) {
  return {
    ...entry.attributes,
    technical_evaluation_assignees: undefined,
    salary_evaluation_assignees: undefined,
    email: undefined,
    id: entry?.id,
  }
}

const employees = ref<Data<Employee>[]>([])

function getEstimatedMonthlySalary(entry: Data<Employee>) {
  const averageWeeksPerMonth = 4.345
  const { salary_type, weekly_hours_capacity, salary } = entry.attributes
  let estimatedMonthlySalary = +salary
  if (salary_type === SalaryTypes.Hourly) {
    estimatedMonthlySalary = (salary || 0) * weekly_hours_capacity * averageWeeksPerMonth
  }
  return estimatedMonthlySalary
}

function transformData(data: any) {
  employees.value = data
  return data
}

function processCellForClipboard(params: ProcessCellForExportParams) {
  const field = params.column?.colDef?.field

  return get(params.node?.data, field)
}

async function onCellValueChanged(params: CellValueChangedEvent) {
  const field = params?.colDef?.field
  await saveOrUpdateEntry(params)
}

async function saveOrUpdateEntry(params: CellValueChangedEvent) {
  const row = params.data
  const isValid = isRowValid(row)
  if (!isValid) {
    return params.data
  }
  const field = params?.colDef?.field as string
  const oldData = {
    ...params.data,
  }
  set(oldData, field, params.oldValue)

  const entry = { ...row }
  set(entry, field, params.newValue)

  let savedData = params.data
  const requestData = getRowData(entry)
  try {
    params.data.loading = true
    if (!entry.id) {
      return
    }
    savedData = await updateInlineEntry({
      url: `/restify/employees/${entry.id}`,
      method: 'put',
      row: requestData,
      params,
    })
    params.node.setData(params.data)
  } catch (err: any) {
    console.error(err)
    params.node.setData(oldData)
    if (!err.handled) {
      error(t('Failed to save changes'))
    }
  }
  return savedData
}

function getAllColumnFields() {
  const fields: string[] = []
  columns.value.forEach((column: any) => {
    column.children.forEach((child: any) => {
      if (child.initialHide) {
        return
      }
      fields.push(child.field)
    })
  })
  return fields
}

const showExportDialog = ref(false)
const selectAllColumnsForExport = ref(true)
const selectedColumnsToExport = ref<string[]>([])

async function toggleExportDialog() {
  selectedColumnsToExport.value = getAllColumnFields()
  await nextTick()
  showExportDialog.value = true
}

function onSelectAllChanged(value: boolean) {
  if (!value) {
    selectedColumnsToExport.value = []
  } else {
    selectedColumnsToExport.value = getAllColumnFields()
  }
}

async function exportEmployees() {
  const date = formatDate(new Date(), CalendarDateFormat)
  grid.value?.api?.exportDataAsExcel({
    columnKeys: selectedColumnsToExport.value.length ? selectedColumnsToExport.value : undefined,
    fileName: `${t(`Members`)} ${date}`,
  })
  showExportDialog.value = false
}

async function exportBankInformation() {
  const confirmed = await $confirm({
    title: t('Export bank information'),
    description: t('Export employees information tip'),
    buttonText: t('Export'),
  })
  if (!confirmed) {
    return
  }
  const date = formatDate(new Date(), CalendarDateFormat)
  grid.value?.api?.exportDataAsExcel({
    columnKeys: [
      'attributes.first_name',
      'attributes.last_name',
      'attributes.contract_bank_name',
      'attributes.contract_bank_swift',
      'attributes.contract_bank_account',
      'attributes.attributes.salary_currency',
    ],
    fileName: `${t(`Employees bank information`)} ${date}`,
  })
}
</script>

<route lang="yaml">
name: Bulk update members
</route>
