<template>
  <div class="flex flex-col h-full">
    <AssetsTable
      :employee-id="currentEmployee.id"
      class="flex-1"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import AssetsTable from "@/modules/assets/components/AssetsTable.vue";
const { currentEmployee } = storeToRefs(useEmployeeStore())
</script>

<route lang="yaml">
name: Employee Assets
</route>
