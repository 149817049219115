<template>
  <FormKit
    type="form"
    :actions="false"
    @submit="onSubmit"
  >
    <ElDialog v-bind="$attrs">
      <div v-focus>
        <FormKit
          v-model="model.name"
          :label="t('Name')"
          :placeholder="t('Name')"
          type="text"
          validation="required|length:0,50"
          outer-class="col-span-3"
        />
      </div>
      <FormKit
        v-model="model.date"
        :label="t('Date')"
        :placeholder="t('Date')"
        :disabled-days="disableDays"
        type="datepicker"
        format="MMMM DD, YYYY"
        outer-class="col-span-3"
        validation="required"
      >
        <template #day="{ classes, day }">
          <DayCell :classes="classes" :day="day" />
        </template>
      </FormKit>

      <template #footer>
        <div class="w-full flex justify-center space-x-2">
          <FormKit
            type="button"
            :classes="{ input: 'min-w-[128px] btn-outline' }"
            @click="emit('close')"
          >
            <span>{{ t('Cancel') }}</span>
          </FormKit>
          <FormKit
            type="submit"
            :classes="{ input: 'min-w-[128px]' }"
            :disabled="loading"
          >
            <span>{{ t('Save') }}</span>
          </FormKit>
        </div>
      </template>
    </ElDialog>
  </FormKit>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import axios from "axios"
import { useI18n } from "vue-i18n"
import { FormKitNode } from "@formkit/core"
import { error, success } from "@/components/common/NotificationPlugin"
import { API_REPORT_DATE_FORMAT, formatDate } from "@/modules/common/utils/dateUtils"
import DayCell from "@/modules/holidays/components/DayCell.vue"
import { useHolidayUtils } from "@/modules/time/composables/useHolidayUtils"

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
})

const emit = defineEmits(['save', 'close'])
const loading = ref(false)
const model = ref({
  id: null,
  name: '',
  date: '',
  year: new Date().getFullYear(),
  payable: true,
})

const { isNationalDay } = useHolidayUtils()

function disableDays(node: FormKitNode, date: Date) {
  return isNationalDay(date)
}

watch(() => props.data, (value) => {
  if (!value?.attributes) {
    return
  }
  model.value = {
    id: value.id,
    name: value.attributes?.name,
    date: value.attributes?.date,
    year: value.attributes?.year,
    payable: value.attributes?.payable,
  }
}, { immediate: true })

const { t } = useI18n()
async function onSubmit() {
  try {
    const { id } = props.data
    const date = new Date(model.value.date)
    const data = {
      ...model.value,
      start_date: formatDate(date, API_REPORT_DATE_FORMAT),
      year: date.getFullYear(),
    }
    let response
    if (id) {
      response = await axios.put(`/restify/legal-holidays/${id}`, data)
    } else {
      response = await axios.post(`/restify/legal-holidays`, data)
    }
    success(t('Document type saved'))
    emit('save', response.data)
  } catch (err: any) {
    if (err.handled) {
      return
    }
    error(t('Could not save the document type'))
  }
}
</script>
