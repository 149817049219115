import { Ref, computed, watch } from 'vue'
import { getContractTypeOptions } from "@/modules/clients/utils/employeeUtils"

export const address = {
  street: '',
  country: '',
  city: '',
  state: '',
  zip_code: '',
}

export function useEmployeeForm(props: any, model: Ref<any>) {
  watch(() => props.employee, (employee) => {
    initModel(employee)
  }, { immediate: true })

  function initModelAddress(addressKey: 'address' | 'company_address') {
    if (!model.value[addressKey]) {
      model.value[addressKey] = {
        ...address,
      }
    } else {
      model.value[addressKey] = {
        ...address,
        ...model.value[addressKey],
      }
    }
  }

  function initModel(employee: any) {
    if (!employee) {
      return
    }

    model.value = {
      ...model.value,
      ...employee,
    }
    initModelAddress('address')
    initModelAddress('company_address')

    if (!model.value.technical_evaluation_assignees) {
      model.value.technical_evaluation_assignees = []
    }
    if (!model.value.salary_evaluation_assignees) {
      model.value.salary_evaluation_assignees = []
    }

    const keysToDelete = [
      'relationships',
      'meta',
    ]
    keysToDelete.forEach((key: string) => {
      delete model.value[key]
    })
  }

  const fullName = computed(() => {
    const { first_name, last_name } = model.value
    if (!first_name && !last_name) {
      return ''
    }
    return `${first_name || ''} ${last_name || ''}`
  })

  const contractTypeLabel = computed(() => {
    return getContractTypeOptions().find(option => option.value === model.value.contract_type)?.label
  })

  return {
    fullName,
    contractTypeLabel,
    initModel,
    initModelAddress,
  }
}
