import { useSettingsStore } from "@/modules/settings/store/settingsStore";
import { storeToRefs } from "pinia";
import { isNationalHoliday } from "@/modules/holidays/utils/holidayUtils";

export function useHolidayUtils() {
  const settingsStore = useSettingsStore()
  const { legalHolidays } = storeToRefs(settingsStore)

  function isNationalDay(date: Date) {
    return isNationalHoliday({ date, nationalHolidays: legalHolidays.value })
  }

  return {
    isNationalDay,
    disableTimeLoggingOnHolidays: settingsStore.disableTimeLoggingOnHolidays,
  }
}
