<template>
  <div>
    <h1 class="text-center">
      {{ $t('Confirm Invitation') }}
    </h1>
    <div class="flex justify-center space-x-2 mt-2">
      <p>{{ $t(`Already have an account?`) }}</p>
      <RouterLink to="/login">
        {{ $t('Sign In') }}
      </RouterLink>
    </div>

    <div class="mt-10">
      <FormKit
        v-slot="{ state: { loading } }"
        type="form"
        :actions="false"
        class="mt-10"
        @submit="onSubmit"
      >
        <FormKit
          v-model="model.email"
          :disabled="true"
          icon="email"
          :name="$t('Email')"
          :label="$t('Email')"
          :placeholder="$t('Email')"
        />

        <FormkitPasswordInput
          v-model="model.password"
          :name="$t('Password')"
          :label="$t('Password')"
          :placeholder="$t('Password')"
          validation="required|length:8|contains_numeric|contains_symbol|contains_uppercase"
          validation-visibility="dirty"
        />

        <div class="w-full mt-12">
          <FormKit
            type="submit"
            :disabled="loading"
            :classes="{ input: 'w-full' }"
          >
            <span>{{ $t('Confirm') }}</span>
            <CheckIcon class="w-4 h-4 ml-1" />
          </FormKit>
        </div>
      </FormKit>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive } from 'vue'
import { useRoute, useRouter } from "vue-router"
import { useI18n } from "vue-i18n"
import { error, success } from '@/components/common/NotificationPlugin'
import FormkitPasswordInput from '@/components/formkit/FormkitPasswordInput.vue'
import { useAuthStore } from "@/modules/auth/store/authStore"
import ConfirmInvitationRequestParams = Auth.ConfirmInvitationRequestParams
import { CheckIcon } from "@heroicons/vue/24/outline"
import { extractEmailFromString } from "@/modules/common/utils/stringUtils"
const router = useRouter()
const route = useRoute()
const { t } = useI18n()

const model: ConfirmInvitationRequestParams = reactive({
  email: extractEmailFromString(window.location.href) || route.query.email,
  token: route.query.token as string,
  password: '',
  password_confirmation: '',
})
const authStore = useAuthStore()

async function onSubmit() {
  try {
    model.password_confirmation = model.password
    await authStore.confirmInvitation(model)
    await authStore.login({
      email: model.email,
      password: model.password,
    })
    success(t('Account created successfully. You will be logged in automatically.'))
    await router.push('/personal-onboarding')
  } catch (err: any) {
    console.error(err)
    if (err.handled) {
      return
    }
    error(t('An error occurred while trying to confirm the invitation.'))
  }
}
</script>

<route lang="yaml">
name: Join Invitation
meta:
  layout: authLayout
</route>
