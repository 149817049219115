<template>
  <time>
    {{ formattedPrice }}
  </time>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { defaultCurrency, formatPrice } from "@/plugins/formatPrice"
import { SettingKeys } from "@/modules/auth/types/enums"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"

const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  },
})

const settingsStore = useSettingsStore()
const defaultCompanyCurrency = computed(() => settingsStore.getSetting(SettingKeys.DefaultCurrency))

const formattedPrice = computed(() => {
  try {
    const currency = props.params.currency || defaultCompanyCurrency.value || defaultCurrency
    const value = props.params.value as number

    return formatPrice(value, {
      currency,
    })
  } catch (err) {
    return ''
  }
})
</script>
