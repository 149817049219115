<template>
  <div
    :class="{
      [classes?.day]: classes?.day,
      'border !border-primary !bg-primary text-white': isStartDate || isEndDate,
      'border !border-primary !border-opacity-70': isBetweenStartAndEnd,
    }"
    :title="isNationalDay(day) ? $t('National Holiday') : ''"
    class="relative min-w-[36px]"
  >
    {{ day.getDate() }}
    <div v-if="isNationalDay(day)" class="bg-red-500 rounded-full w-1.5 h-1.5 absolute bottom-0" />
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia"
import { PropType, computed } from "vue"
import { isSameDay } from "date-fns"
import { isNationalHoliday } from "@/modules/holidays/utils/holidayUtils"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { useHolidayUtils } from "@/modules/time/composables/useHolidayUtils";

const props = defineProps({
  classes: {
    type: Object as PropType<any>,
    default: () => ({}),
  },
  day: {
    type: Date,
    default: () => ({}),
  },
  startDate: {
    type: Date,
  },
  endDate: {
    type: Date,
  },
})

const settingsStore = useSettingsStore()
const { legalHolidays } = storeToRefs(settingsStore)

const date = computed(() => {
  const day = new Date(props.day)
  day.setHours(0, 0 ,0)
  return day
})
const isStartDate = computed(() => {
  if (!props.startDate) {
    return false
  }
  return isSameDay(date.value, new Date(props.startDate))
})

const isEndDate = computed(() => {
  if (!props.endDate) {
    return false
  }
  return isSameDay(date.value, new Date(props.endDate))
})

const isBetweenStartAndEnd = computed(() => {
  if (!props.startDate || !props.endDate) {
    return false
  }
  const currentDate = date.value?.getTime()
  const start = new Date(props.startDate).getTime()
  const end = new Date(props.endDate).getTime()
  return currentDate > start && currentDate < end
})

const { isNationalDay } = useHolidayUtils()
</script>
