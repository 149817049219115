<template>
  <BaseFormCard
    :show-actions="false"
    class="flex flex-1"
    @submit="updateEmployee"
  >
    <div class="flex-1 mt-4">
      <div v-if="step === EmployeeOnboardingSteps.PersonalInfo" class="grid grid-cols-6 gap-x-4">
        <div id="avatar-section" class="col-span-6 md:col-span-2 flex h-full justify-center items-center">
          <AvatarUpload v-model="model.avatar" :name="fullName" />
        </div>
        <div class="grid grid-cols-6 gap-x-4 col-span-6 md:col-span-4">
          <FormKit
            v-model="model.first_name"
            name="first_name"
            type="text"
            :label="t('First Name')"
            :placeholder="t('John')"
            validation="required|length:0,255"
            outer-class="col-span-6 md:col-span-3"
          />

          <FormKit
            v-model="model.last_name"
            name="last_name"
            type="text"
            :label="t('Last Name')"
            :placeholder="t('Doe')"
            validation="required|length:0,255"
            outer-class="col-span-6 md:col-span-3"
          />

          <FormKit
            v-model="model.email"
            name="email"
            :disabled="true"
            type="email"
            :label="t('Work Email')"
            :placeholder="t('john.doe@company.com')"
            validation="required|email"
            outer-class="col-span-6 md:col-span-3"
          />
          <FormKit
            v-model="model.personal_email"
            name="personal_email"
            type="email"
            :label="t('Personal Email')"
            :placeholder="t('john.doe@gmail.com')"
            validation="email"
            outer-class="col-span-6 md:col-span-3"
          />
          <FormKit
            :key="isSmallScreen"
            v-model="birthDate"
            name="birth_date"
            :type="isSmallScreen ? 'date' : 'customDatePicker'"
            :label="t('Date of Birth')"
            :placeholder="t('10.10.1990')"
            validation="required"
            outer-class="col-span-6 md:col-span-3"
          />
          <FormKit
            v-model="model.phone"
            name="phone"
            :label="t('Phone')"
            :placeholder="t('0741 234 567')"
            validation="phone"
            outer-class="col-span-6 md:col-span-3"
          />
        </div>
      </div>
      <div v-else-if="step === EmployeeOnboardingSteps.LocationInfo" class="grid grid-cols-6 gap-x-4">
        <FormKit
          v-model="model.personal_identification_number"
          name="personal_identification_number"
          type="text"
          :label="t('Personal Identification Number')"
          :placeholder="t('1230110220022')"
          :validation-label="t('Personal Identification Number')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.identity_card_number"
          name="identity_card_number"
          type="text"
          :label="t('Identity Card Number')"
          :placeholder="t('CJ123456')"
          outer-class="col-span-6 md:col-span-2"
        />

        <div class="mt-6 col-span-6" />

        <FormKit
          v-model="model.address.street"
          name="address_street"
          type="text"
          :label="t('Home Street')"
          :placeholder="t('Startdust Way 23')"
          outer-class="col-span-6 md:col-span-3"
        />

        <FormKit
          v-model="model.address.zip_code"
          name="address_zip"
          type="text"
          :label="t('Zip Code')"
          :placeholder="t('8GJ 7H8')"
          outer-class="col-span-6 md:col-span-1"
        />

        <FormKit
          v-model="model.address.city"
          name="address_city"
          type="text"
          :label="t('City')"
          :placeholder="t('Cluj-Napoca')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.address.state"
          name="address_state"
          type="text"
          :label="t('State')"
          :placeholder="t('Cluj')"
          outer-class="col-span-6 md:col-span-2"
        />
        <FormKit
          v-model="model.address.country"
          name="address_country"
          type="countrySelect"
          :label="t('Country')"
          :placeholder="t('Country')"
          outer-class="col-span-6 md:col-span-2"
        />

        <FormKit
          v-model="model.timezone"
          name="timezone"
          type="timezoneSelect"
          :label="t('Timezone')"
          :placeholder="t('(UTC+02:00) Athens, Bucharest')"
          outer-class="col-span-6 md:col-span-2"
        />

        <div class="mt-6 col-span-6" />
        <FormKit
          v-model="model.about"
          name="about"
          type="textarea"
          :label="t('A Little About Me')"
          :placeholder="t('I’m Spencer Sharp. I live in Cluj-Napoca, where I develop the future')"
          rows="4"
          validation="length:0,255"
          outer-class="col-span-6 md:col-span-4"
        />
        <FormKit
          v-model="model.quote"
          name="quote"
          type="textarea"
          :label="t('Favorite quote')"
          :placeholder="t('A person who never made a mistake, never tried anything new. \n\nAlbert Einstein')"
          rows="4"
          validation="length:0,255"
          outer-class="col-span-6 md:col-span-2"
        />
      </div>
      <div v-else-if="step === EmployeeOnboardingSteps.ContractInfo" class="grid grid-cols-6 gap-x-4">
        <ContentCardRow class="col-span-6">
          <template #title>
            <span class="text-sm text-base-300 font-medium px-0">
              {{ $t('Contract Type') }}
            </span>
          </template>
          <span class="text-gray-700 font-semibold">{{ contractTypeLabel || '- -' }}</span>
        </ContentCardRow>
        <template v-if="isB2BContract(model.contract_type)">
          <FormKit
            v-model="model.company_name"
            name="company_name"
            type="text"
            :label="t('Company Name')"
            :placeholder="t('Creative Development Solutions SRL')"
            outer-class="col-span-6 md:col-span-2"
          />
          <FormKit
            v-model="model.company_position"
            name="company_position"
            type="text"
            :label="t('Company Position')"
            :placeholder="t('Administrator')"
            outer-class="col-span-6 md:col-span-2"
          />
          <FormKit
            v-model="model.company_vat"
            name="company_vat"
            type="text"
            :label="t('VAT Number')"
            :placeholder="t('RO32392318')"
            outer-class="col-span-6 md:col-span-1"
          />
          <FormKit
            v-model="model.company_identifier"
            name="company_identifier"
            type="text"
            :label="t('Register No.')"
            :placeholder="t('J12/1234/2018 ')"
            outer-class="col-span-6 md:col-span-1"
          />

          <FormKit
            v-model="model.company_address.street"
            name="company_address_street"
            type="text"
            :label="t('Home Street')"
            :placeholder="t('Startdust Way 23')"
            outer-class="col-span-6 md:col-span-3"
          />

          <FormKit
            v-model="model.company_address.zip_code"
            name="company_address_zip"
            type="text"
            :label="t('Zip Code')"
            :placeholder="t('8GJ 7H8')"
            outer-class="col-span-6 md:col-span-1"
          />

          <FormKit
            v-model="model.company_address.city"
            name="company_address_city"
            type="text"
            :label="t('City')"
            :placeholder="t('Cluj-Napoca')"
            outer-class="col-span-6 md:col-span-2"
          />

          <FormKit
            v-model="model.company_address.state"
            name="company_address_state"
            type="text"
            :label="t('State')"
            :placeholder="t('Cluj')"
            outer-class="col-span-6 md:col-span-2"
          />
          <FormKit
            v-model="model.company_address.country"
            name="company_address_country"
            type="countrySelect"
            :label="t('Country')"
            :placeholder="t('Country')"
            outer-class="col-span-6 md:col-span-2"
          />
        </template>
        <div class="col-span-6 mt-6" />
        <FormKit
          v-model="model.contract_bank_name"
          name="contract_bank_name"
          type="text"
          :label="t('Bank')"
          :placeholder="t('ING Bank Cluj-Napoca')"
          outer-class="col-span-6 md:col-span-3"
        />
        <FormKit
          v-model="model.contract_bank_swift"
          name="contract_bank_swift"
          type="text"
          :label="t('Swift Code')"
          :placeholder="t('BTRLRO22XXX')"
          outer-class="col-span-6 md:col-span-1"
        />
        <FormKit
          v-model="model.contract_bank_account"
          name="contract_bank_account"
          type="text"
          :label="t('IBAN')"
          :placeholder="t('RO22 AAAA 1B31 0012 3456 7890')"
          outer-class="col-span-6 md:col-span-2"
        />
      </div>
    </div>
    <div class="flex justify-between bg-white mt-4 py-4 border-t border-gray-200 sticky bottom-0">
      <div>
        <BaseButton
          v-if="showBack"
          :left-icon="ArrowLeftIcon"
          variant="secondary"
          class="min-w-[128px]"
          @click="emit('back')"
        >
          {{ $t('Back') }}
        </BaseButton>
      </div>
      <div class="flex space-x-2 items-center">
        <BaseButton
          type="submit"
          variant="primary"
          :loading="loading"
          :right-icon="step === EmployeeOnboardingSteps.ContractInfo ? undefined : ArrowRightIcon"
          class="min-w-[128px]"
        >
          {{ nextText || $t('Next') }}
        </BaseButton>
      </div>
    </div>
  </BaseFormCard>
</template>

<script setup lang="ts">
import { ref, computed } from "vue"
import { useI18n } from "vue-i18n"
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/vue/24/outline"
import { useMediaQuery } from "@vueuse/core"
import {
  ContractTypes,
  EmployeeOnboardingSteps,
  isB2BContract,
} from "@/modules/employees/utils/employeeUtils"
import AvatarUpload from "@/components/form/AvatarUpload.vue"
import { address, useEmployeeForm } from "@/modules/employees/composables/useEmployeeForm"
import { API_DATE_FORMAT, formatDate } from "@/modules/common/utils/dateUtils"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import ContentCardRow from "@/components/common/ContentCardRow.vue"
import { format } from "date-fns";

const { t } = useI18n()

const props = defineProps({
  employee: {
    type: Object,
    default: () => ({}),
  },
  loading: Boolean,
  step: {
    type: Number,
  },
  nextText: {
    type: String,
  },
  showBack: {
    type: Boolean,
    default: true,
  },
})

const model = ref({
  avatar: null,
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  personal_email: '',
  birth_date: '',
  personal_identification_number: '',
  identity_card_number: '',
  address: {
    ...address,
  },
  company_address: {
    ...address,
  },
  timezone: '',
  about: '',
  quote: '',
  contract_type: '' as ContractTypes,
  contract_bank_name: '',
  contract_bank_swift: '',
  contract_bank_account: '',
  company_name: '',
  company_position: '',
  company_vat: '',
  company_identifier: '',
})

const { fullName, contractTypeLabel } = useEmployeeForm(props, model)

const loading = ref(false)
const employeeStore = useEmployeeStore()

const isSmallScreen = useMediaQuery('(max-width: 768px)')

const birthDate = computed({
  get() {
    if (model.value.birth_date && isSmallScreen.value) {
      return format(new Date(model.value.birth_date), 'yyyy-MM-dd')
    }
    return model.value.birth_date
  },
  set(value: any) {
    model.value.birth_date = value
  },
})

const emit = defineEmits(['back', 'next'])

async function updateEmployee() {
  try {
    const data: any = {
      ...model.value,
      email: undefined,
    }
    if (data.birth_date) {
      data.birth_date = formatDate(data.birth_date, API_DATE_FORMAT)
    }
    loading.value = true
    await employeeStore.updateEmployee(data)
    await employeeStore.getOwnEmployee()
    emit('next')
  } catch (err: any) {
    if (err.handled) {
      return
    }
  } finally {
    loading.value = false
  }
}
</script>

<style>
</style>
