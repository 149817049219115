<template>
  <div>
    <BaseDataTable
      :data-loading="currentReportLoading"
      :columns="columns"
      :data="reportData"
      :pagination="false"
      :row-height="55"
      :extra-actions="rowActions"
      dom-layout="autoHeight"
    >
      <template v-if="reportType === ReportTypes.Tasks" #header-actions-right>
        <TasksBarChart :data="currentReport.data" />
      </template>
      <template #name="{ row, index }">
        <EmployeeLink
          v-if="reportType === ReportTypes.Team"
          :to="getReportLink(row)"
          :params="{ value: row?.attributes?.entity_id, data: getEmployeeFromList(row) }"
        />
        <router-link
          v-else
          :to="getReportLink(row)" class="no-underline text-gray-900 flex items-center space-x-2"
        >
          <div
            v-if="reportType === ReportTypes.Tasks"
            :key="index"
            :style="{ backgroundColor: getTaskColor(index) }"
            class="rounded min-w-[16px] w-4 h-4"
          />
          <span>{{ row?.attributes?.entity_name }}</span>
          <Status v-if="row?.attributes?.archived" :params="{ value: 'archived' }" />
        </router-link>
      </template>

      <template #total_minutes="{ row }">
        <span v-if="row.attributes.no_logs" class="text-gray-500">
          {{ formatHours(row) }}
        </span>
        <router-link v-else :to="getDetailedLink(row)">
          {{ formatHours(row) }}
        </router-link>
      </template>
      <template #total_available_hours="{ row }">
        <div class="flex items-center justify-end space-x-4">
          <LoggedTimeCapacityBarChart
            :item="row?.attributes"
          />
          <span class="text-gray-600 min-w-[40px]">
            {{ (+(row.attributes?.total_available_hours || '0'))?.toFixed(2) }}
          </span>
        </div>
      </template>

      <!-- Capacity report -->
      <template #employee_name="{ row }">
        <EmployeeLink
          v-if="reportType === ReportTypes.Capacity"
          :id="row.id"
          :params="{ value: row?.id }"
          :show-link="false"
        />
      </template>

      <template #capacity_percentage="{ row }">
        <div class="flex items-center justify-end space-x-4">
          <HolidayCapacityBarChart
            :item="row"
          />
          <span class="text-gray-600 min-w-[40px]">
            {{ (+(row?.available_hours || '0'))?.toFixed(2) }}
          </span>
        </div>
      </template>
    </BaseDataTable>
  </div>
</template>

<script lang="ts" setup>
import { PropType, computed, onBeforeMount, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { CellClassParams, ColDef, ValueFormatterParams, ValueGetterParams } from '@ag-grid-community/core'
import { DocumentIcon, EyeIcon, InboxIcon } from "@heroicons/vue/24/outline"
import { formatHours } from "../utils/reportUtils"
import { ReportItem, ReportTypes, useReportsStore } from '@/modules/reports/store/reportsStore'
import { useEmployeeStore } from '@/modules/employees/store/employeeStore'
import { useProjectStore } from '@/modules/projects/store/projectStore'
import { useClientStore } from '@/modules/clients/store/clientStore'
import { API_REPORT_DATE_FORMAT, formatDate } from '@/modules/common/utils/dateUtils'
import { getTaskColor } from '@/modules/reports/enum/reportEnums'
import TasksBarChart from '@/modules/reports/components/TasksBarChart.vue'
import EmployeeLink from '@/components/table/cells/EmployeeLink.vue'
import { isMobile } from "@/util/responsiveUtils"
import { formatPrice } from "@/plugins/formatPrice"
import { can } from "@/plugins/permissionPlugin"
import LoggedTimeCapacityBarChart from "@/modules/reports/components/LoggedTimeCapacityBarChart.vue"
import { ColumnTypes } from "@/components/table/cells/tableCellComponents"
import Data = API.Data
import Employee = App.Domains.Employees.Models.Employee
import HolidayCapacityBarChart from "@/modules/reports/components/HolidayCapacityBarChart.vue"
import { EmployeeStatus } from "@/modules/clients/utils/employeeUtils"
import { RowAction } from "@/components/table/tableTypes"
import i18n from "@/i18n"

const props = defineProps({
  reportType: {
    type: String,
    default: ReportTypes.Team,
  },
  showEmployeesWithoutLogs: {
    type: Boolean,
    default: false,
  },
  employees: {
    type: Array as PropType<Data<Employee>[]>,
    default: () => [],
  },
  columns: {
    type: Array as PropType<ColDef[]>,
  },
  forProject: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()

const reportStore = useReportsStore()
const employeeStore = useEmployeeStore()
const { currentReport, currentReportLoading } = storeToRefs(reportStore)
reportStore.currentReport.reportType = props.reportType

const reportData = computed(() => {
  if (!props.showEmployeesWithoutLogs) {
    return currentReport.value.data
  }
  const reportEmployeeIds = currentReport.value.data.map((e: any) => e.attributes.employee_id || e.attributes.entity_id)
  const archivedEmployees = props.employees.filter((e: any) => {
    return e.attributes.status === EmployeeStatus.Archived || e.pivots.archived_at !== null
  })
  const difference = props.employees.filter(x => !reportEmployeeIds.includes(x.id) && !archivedEmployees.includes(x))
  const employeesWithoutLogs = difference.map((employee: Data<Employee>) => {
    return {
      id: employee.id,
      attributes: {
        entity_id: employee.id,
        name: employee?.attributes?.name,
        total_minutes: 0,
        total_billed_rate: 0,
        no_logs: true,
      },
    }
  })
  return [...currentReport.value.data, ...employeesWithoutLogs]
})
const hoursColumn: ColDef = {
  headerName: t('Hours'),
  field: 'total_minutes',
  type: 'rightAligned',
  cellClass: 'font-medium text-right',
  headerClass: 'ag-right-aligned-header',
  resizable: false,
  sortable: true,
  sortingOrder: ['desc', 'asc', null],
  minWidth: isMobile() ? 140 : 200,
  valueGetter: (params: ValueGetterParams) => {
    return +params.data?.attributes?.total_worked_minutes
  },
}

const capacityColumn: ColDef = {
  headerName: t('Capacity'),
  field: 'total_available_hours',
  type: 'rightAligned',
  cellClass: 'font-medium text-right',
  headerClass: 'ag-right-aligned-header',
  resizable: false,
  sortable: true,
  sortingOrder: ['desc', 'asc', null],
  minWidth: isMobile() ? 240 : 400,
  valueGetter: (params: ValueGetterParams) => {
    return +params.data?.attributes?.total_available_hours
  },
  valueFormatter: (params) => {
    return params.value?.toFixed(2)
  },
}

const costColumn: ColDef = {
  headerName: t('Billable amount'),
  field: 'attributes.total_billed_rate',
  type: 'rightAligned',
  cellClass: (params: CellClassParams) => {
    const { no_logs } = params.data?.attributes || {}
    if (no_logs) {
      return 'text-gray-500 text-right'
    }
    return 'font-bold text-right'
  },
  headerClass: 'ag-right-aligned-header',
  resizable: false,
  sortable: true,
  minWidth: 160,
  sortingOrder: ['desc', 'asc', null],
  hide: !can('manageInvoices'),
  valueFormatter: (params: ValueFormatterParams) => {
    let value = params.value
    if (params.value === null || params.value === undefined) {
      value = 0
    }
    return formatPrice(value, {
      maximumFractionDigits: 2,
    })
  },
  valueGetter: (params: ValueGetterParams) => {
    return +params.data?.attributes?.total_billed_rate
  },
}

const route = useRoute()

function getEmployeeFromList(row: Data<ReportItem>) {
  const employee = props.employees.find(e => e.id === row.attributes?.entity_id)
  if (!employee && props.reportType === ReportTypes.Team) {
    return {
      id: row.attributes?.entity_id,
      attributes: {
        first_name: row.attributes?.entity_name,
        last_name: '',
        status: EmployeeStatus.Archived,
      },
    }
  }
}
function getReportLink(row: Data<ReportItem>) {
  const query = getDateQueryParams()

  if (props.reportType === ReportTypes.Projects) {
    return {
      path: `/reports/projects/${row.attributes?.entity_id}/tasks`,
      query,
    }
  }
  if (props.reportType === ReportTypes.Team) {
    return {
      path: `/reports/team/${row.attributes?.entity_id}/projects`,
      query,
    }
  }
  return getDetailedLink(row)
}

function getDateQueryParams() {
  const start_date = formatDate(reportStore.currentReport.from, API_REPORT_DATE_FORMAT)
  const end_date = formatDate(reportStore.currentReport.to, API_REPORT_DATE_FORMAT)
  return {
    start_date,
    end_date,
  }
}

const projectStore = useProjectStore()

function getDetailedLink(row: Data<ReportItem>) {
  if (!row.attributes) {
    return route
  }
  const queryParams: any = getDateQueryParams()
  if (route.path.startsWith('/reports/team')) {
    queryParams.employee_id = route.params.id
  } else if (route.path.startsWith('/reports/projects')) {
    queryParams.project_id = route.params.id
    queryParams.client_id = projectStore.getProjectClient(queryParams.project_id)
  }
  if (props.reportType === ReportTypes.Team) {
    queryParams.employee_id = row.attributes.entity_id
  } else if (props.reportType === ReportTypes.Tasks) {
    queryParams.task_id = row.attributes.entity_id
  } else if (props.reportType === ReportTypes.Projects) {
    queryParams.project_id = row.attributes.entity_id
    const client_id = projectStore.getProjectClient(queryParams.project_id)
    queryParams.client_id = client_id
  }
  if (currentReport.value.intervalType) {
    queryParams.interval_type = currentReport.value.intervalType
  }
  return {
    path: '/reports/detailed',
    query: queryParams,
  }
}

const nameColumn: ColDef = {
  headerName: t('Name'),
  field: 'name',
  minWidth: isMobile() ? 180 : 200,
  sortable: true,
  resizable: false,
}
const employeeColumns = ref<ColDef[]>([
  nameColumn,
  hoursColumn,
  capacityColumn,
  costColumn,
])

const tasksColumns = ref<ColDef[]>([
  nameColumn,
  hoursColumn,
  costColumn,
])

const projectsColumns = ref<ColDef[]>([
  nameColumn,
  {
    headerName: t('Client'),
    field: 'attributes.entity_id',
    minWidth: 150,
    valueFormatter: (params: ValueFormatterParams) => {
      const projectId = params.value
      const project = useProjectStore().getProjectById(projectId)
      if (!project) {
        return ''
      }
      const client = useClientStore().getClientById(project.attributes.client_id)
      return client?.attributes?.company_name || ''
    },
  },
  hoursColumn,
  costColumn,
])

const clientColumns = ref<ColDef[]>([
  {
    headerName: t('Client'),
    field: 'company_name',
    minWidth: 150,
    sortable: true,
  },
  {
    headerName: t('Invoiced Amount'),
    field: 'totals.total_invoiced_amount',
    type: ColumnTypes.Price,
    sortable: true,
    minWidth: 160,
    sortingOrder: ['desc', 'asc', null],
    hide: !can('manageInvoices'),
  },
  {
    headerName: t('Paid Amount'),
    field: 'totals.total_paid_amount',
    type: ColumnTypes.Price,
    sortable: true,
    minWidth: 160,
    sortingOrder: ['desc', 'asc', null],
    hide: !can('manageInvoices'),
  },
  {
    headerName: t('Cost'),
    field: 'totals.total_actual_cost',
    type: ColumnTypes.Price,
    sortable: true,
    minWidth: 160,
    sortingOrder: ['desc', 'asc', null],
    hide: !can('manageInvoices'),
  },
  {
    headerName: t('Profitability'),
    field: 'totals.total_actual_profitability',
    type: ColumnTypes.Price,
    sortable: true,
    minWidth: 160,
    sortingOrder: ['desc', 'asc', null],
    cellClass: (params: CellClassParams) => {
      const value = params.value
      return value < 0 ? 'text-red-600' : ''
    },
    hide: !can('manageInvoices'),
  },
])

const capacityColumns = ref<ColDef[]>([
  {
    ...nameColumn,
    field: 'employee_name',
  },
  {
    headerName: t('Weekly allocation'),
    field: 'weekly_capacity',
    minWidth: isMobile() ? 50 : 100,
    sortable: true,
    resizable: false,
    type: 'rightAligned',
    valueGetter: (params: ValueGetterParams) => {
      let value = +params.data.weekly_capacity
      if (props.forProject) {
        value = +params.data.weekly_allocation_hours_per_project
      }
      return value.toFixed(2)
    },
    valueFormatter: (params: ValueFormatterParams) => {
      return `${params.value} ${t('hours')}`
    },
  },
  {
    headerName: t('National holidays'),
    field: 'national_holidays',
    minWidth: isMobile() ? 50 : 100,
    maxWidth: 200,
    sortable: true,
    resizable: false,
    type: 'rightAligned',
  },
  {
    headerName: t('Days off'),
    field: 'days_off',
    minWidth: isMobile() ? 50 : 100,
    maxWidth: 150,
    sortable: true,
    resizable: false,
    type: 'rightAligned',
  },
  {
    headerName: t('Working days'),
    field: 'total_workdays',
    minWidth: isMobile() ? 50 : 100,
    maxWidth: 200,
    sortable: true,
    resizable: false,
    valueGetter: (params: ValueGetterParams) => {
      const daysOff = +params.data.total_days_off
      const workingDays = +params.data.total_workdays
      return workingDays - daysOff
    },
    type: 'rightAligned',
  },
  {
    headerName: t('Capacity'),
    field: 'capacity_percentage',
    type: 'rightAligned',
    cellClass: 'font-medium text-right',
    headerClass: 'ag-right-aligned-header',
    minWidth: 250,
    resizable: false,
    sortable: true,
    sortingOrder: ['desc', 'asc', null],
  },
])

const router = useRouter()
const rowActions = computed<RowAction[]>(() => {
  const detailedReportAction = {
    label: i18n.t('Detailed report'),
    icon: DocumentIcon,
    action: async (row: Data<any>) => {
      await router.push(getDetailedLink(row))
    },
  }

  const actionMap = {
    [ReportTypes.Team]: [detailedReportAction],
    [ReportTypes.Tasks]: [detailedReportAction],
    [ReportTypes.Projects]: [
      {
        label: i18n.t('Project report'),
        icon: InboxIcon,
        action: async (row: Data<any>) => {
          await router.push(getReportLink(row))
        },
      },
      detailedReportAction,
    ],
    [ReportTypes.Clients]: [],
    [ReportTypes.Capacity]: [],
  }
  return actionMap[props.reportType] || []
})

const columns = computed(() => {
  const columnMap = {
    [ReportTypes.Team]: employeeColumns.value,
    [ReportTypes.Tasks]: tasksColumns.value,
    [ReportTypes.Projects]: projectsColumns.value,
    [ReportTypes.Clients]: clientColumns.value,
    [ReportTypes.Capacity]: capacityColumns.value,
  }
  if (props.columns) {
    return props.columns
  }
  return columnMap[props.reportType] || []
})

onMounted(() => {
  reportStore.getCurrentReport()
})
onBeforeMount(() => {
  reportStore.resetCurrentReport()
})
</script>
