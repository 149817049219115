<template>
  <SettingsCrudPage
    ref="table"
    :title="$t('Company positions')"
    :modal-component="PositionModal"
    :add-text="$t('Add position')"
    :edit-text="$t('Edit position')"
    :url-params="urlParams"
    :group-default-expanded="-1"
    :suppress-agg-func-in-header="true"
    :columns="columns"
    group-display-type="groupRows"
    entity="positions"
  >
    <template #after-title>
      <PositionTabs />
    </template>
  </SettingsCrudPage>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useI18n } from "vue-i18n"
import { ColDef, ValueFormatterParams, ValueSetterParams } from "@ag-grid-community/core"
import SettingsCrudPage from "@/modules/settings/components/SettingsCrudPage.vue"
import PositionModal from "@/modules/settings/components/PositionModal.vue"
import PositionTabs from "@/modules/settings/components/positions/PositionTabs.vue"
import { useSettingsStore } from "@/modules/settings/store/settingsStore";
import { storeToRefs } from "pinia";
import { tableCellEditors } from "@/components/table/cells/tableCellComponents";

const urlParams = {
  related: 'department',
  sort: 'department.name',
}
const settingsStore = useSettingsStore()
const { departments } = storeToRefs(settingsStore)
const table = ref()
const { t } = useI18n()

const columns = ref<ColDef[]>([
  {
    headerName: t('Name'),
    field: 'attributes.name',
    editable: true,
    valueSetter: (params: ValueSetterParams) => {
      if (!params.newValue) {
        return false
      }
      params.data.attributes.name = params.newValue
      table.value?.updateRow(params.data)
      return true
    },
  },
  {
    headerName: t('Department'),
    field: 'attributes.department_id',
    editable: true,
    sortable: true,
    sort: 'asc',
    cellEditor: tableCellEditors.DepartmentCellEditor,
    valueFormatter: (params: ValueFormatterParams) => {
      const departmentName = params.data?.relationships?.department?.attributes?.name
      if (departmentName) {
        return departmentName
      }
      return departments.value.find((department: any) => department.id === params.data?.attributes?.department_id)?.attributes?.name || ''
    },
    valueSetter: (params: ValueSetterParams) => {
      if (!params.newValue) {
        return false
      }
      params.data.attributes.department_id = params.newValue
      table.value?.updateRow(params.data)
      return true
    },
  },
  {
    headerName: t('Description'),
    field: 'attributes.description',
    editable: true,
    cellEditor: 'agLargeTextCellEditor',
    cellEditorPopup: true,
    valueSetter: (params: ValueSetterParams) => {
      if (!params.newValue) {
        return false
      }
      params.data.attributes.description = params.newValue
      table.value?.updateRow(params.data)
      return true
    },
  },
])
</script>

<route lang="yaml">
name: Positions
meta:
  permission: managePositions
</route>
