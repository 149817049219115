<template>
  <div class="flex flex-col relative">
    <template v-if="!isEditPage && !isNationalHolidaysPage">
      <div
        class="flex items-end w-full justify-between"
      >
        <PageTitle
          v-if="!isProfilePage"
          :loading="pageLoading"
        >
          {{ employeeName }}
        </PageTitle>
        <EditButton
          v-if="can('manageEmployees') && isDetailsPage || isProfilePage"
          class="employee-edit-button"
          @click="goToEdit"
        />
      </div>
      <div v-if="!isProfilePage && tabs.length">
        <TabLinks v-model="activeTab" :tabs="tabs" />
      </div>
      <div v-else class="mb-4" />
    </template>
    <div
      v-if="currentEmployeeError || pageLoading"
      class="flex-1 flex justify-center items-center h-[50vh]"
    >
      <LoadingTable v-if="pageLoading" />
      <div v-else class="flex flex-col items-center">
        <IconBox class="mb-4" color="error">
          <ExclamationTriangleIcon class="w-8 h-8" />
        </IconBox>
        <h2>{{ $t('An error occurred while trying to access the employee information') }}</h2>
      </div>
    </div>
    <RouterView v-else class="flex-1" />
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import {
  CalendarIcon,
  ChartBarSquareIcon,
  ChatBubbleLeftRightIcon, ComputerDesktopIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  ExclamationTriangleIcon,
  PresentationChartLineIcon,
  PuzzlePieceIcon,
  Squares2X2Icon, UserIcon,
} from "@heroicons/vue/24/outline"
import { storeToRefs } from "pinia"
import { useHead } from "@vueuse/head"
import { useI18n } from "vue-i18n"
import i18n from "@/i18n"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import TabLinks from '@/components/tabs/TabLinks.vue'
import EditButton from "@/components/common/buttons/EditButton.vue"
import { can, isModuleVisible } from "@/plugins/permissionPlugin"
import { useAuth } from "@/modules/auth/composables/useAuth"
import { ModuleKeys } from "@/modules/auth/types/enums"
const props = defineProps({
  employeeId: {
    type: String,
  },
  isProfilePage: {
    type: Boolean,
  },
})
const activeTab = ref('Details')
const employeeStore = useEmployeeStore()

const route = useRoute()

const {
  currentEmployeeName: employeeName,
  currentEmployee,
  currentEmployeeError,
  currentEmployeeLoading,
} = storeToRefs(employeeStore)

const id = computed(() => props.employeeId as string)

const pageLoading = computed(() => {
  return currentEmployeeLoading.value && currentEmployee.value.id !== id.value
})

const { t } = useI18n()
const { isOwnEmployee } = useAuth()

const tabs = computed(() => {
  const basePath = `/employees/${id.value}`
  const tabs: any[] = [
    {
      label: t('Details'),
      icon: UserIcon,
      path: `${basePath}/details`,
      show: true,
      permission: can('manageEmployees') || isOwnEmployee.value,
    },
    {
      label: t('Documents'),
      icon: DocumentDuplicateIcon,
      path: `${basePath}/documents`,
      permission: (can('manageDocuments') || isOwnEmployee.value) && isModuleVisible(ModuleKeys.Documents),
    },
    {
      label: t('Evaluations'),
      icon: PresentationChartLineIcon,
      path: `${basePath}/evaluations`,
      permission: (can('manageEvaluations') || isOwnEmployee.value) && isModuleVisible(ModuleKeys.Evaluations),
    },
    {
      label: t('Feedback'),
      icon: ChatBubbleLeftRightIcon,
      path: `${basePath}/feedback`,
      permission: (can('manageFeedback') || isOwnEmployee.value) && isModuleVisible(ModuleKeys.Feedback),
    },
    {
      label: t('Holidays'),
      icon: CalendarIcon,
      path: `${basePath}/holidays`,
      permission: (can('manageHolidays') || isOwnEmployee.value) && isModuleVisible(ModuleKeys.Holidays),
    },
    {
      label: t('Assets'),
      icon: ComputerDesktopIcon,
      path: `${basePath}/assets`,
      permission: (can('manageAssets') || isOwnEmployee.value) && isModuleVisible(ModuleKeys.Assets),
    },
    {
      label: t('Departments'),
      icon: Squares2X2Icon,
      path: `${basePath}/departments`,
      show: false,
      permission: can('manageDepartments'),
    },
    {
      label: t('Projects'),
      icon: PuzzlePieceIcon,
      path: `${basePath}/projects`,
      show: false,
    },
    {
      label: i18n.t('Goals'),
      icon: ChartBarSquareIcon,
      path: `${basePath}/goals`,
      permission: can('manageGoals') || isOwnEmployee.value,
    },
  ]
  return tabs.filter(tab => tab.show !== false && tab.permission === true)
})

const isEditPage = computed(() => {
  return route.path.endsWith('edit')
})

const isDetailsPage = computed(() => {
  return route.path.endsWith('details')
})

const isNationalHolidaysPage = computed(() => {
  return route.path.endsWith('holidays/national')
})

const pageTitle = computed(() => {
  if (!employeeName.value) {
    return activeTab.value
  }
  return `${activeTab.value} - ${employeeName.value}`
})

useHead({
  title: pageTitle,
})
watch(() => id.value, async (value: string) => {
  await employeeStore.getEmployee(value)
})
onMounted(async () => {
  await employeeStore.getEmployee(id.value)
})
onUnmounted(() => {
  employeeStore.resetCurrentEmployee()
})

const router = useRouter()

async function goToEdit() {
  if (props.isProfilePage) {
    await router.push(`/settings/account/employee-profile/edit`)
    return
  }
  await router.push(`/employees/${id.value}/edit`)
}
</script>
