<template>
  <div class="daily-timesheet">
    <div class="flex flex-col space-y-2 divide-y divide-gray-300">
      <template v-if="!loading">
        <div v-for="time in timeSheets" :key="time.id" class="py-4 px-2 flex justify-between">
          <div>
            <div class="font-semibold">
              {{ getProjectName(time) }}
            </div>
            <div class="text-gray-700 leading-5">
              {{ getTaskName(time) }}
            </div>
            <div
              v-if="time.attributes.description"
              class="text-xs text-gray-500 mt-0.5 whitespace-pre truncate max-w-[60vw]"
              v-html="time.attributes.description"
            />
            <div
              v-if="settingsStore.showTimesheetDates && can('manageTimesheets')"
              class="text-xs text-gray-500 mt-0.5"
            >
              {{ formatDate(time.attributes.created_at, DATE_TIME_FORMAT) }}
            </div>
          </div>
          <div class="font-semibold text-lg flex space-x-4 items-center">
            <span>{{ minutesToHours(time.attributes.worked_minutes) }}</span>
            <TimerButton
              :time="time"
              :other-logs="timeSheets"
              @update:worked_minutes="updateWorkedMinutes(time, $event)"
            />
            <BaseButton
              outline
              size="xs"
              variant="secondary"
              @click="emit('edit', time)"
            >
              {{ $t('Edit') }}
            </BaseButton>
          </div>
        </div>
      </template>
      <div v-if="!loading && timeSheets.length > 0" class="flex justify-end pr-2 py-3">
        <span class="mr-2 text-lg font-medium text-gray-700">{{ $t('Total:') }}</span>
        <span class="font-semibold text-lg">{{ minutesToHours(getTotalMinutesForDay(timeSheets, selectedDay)) }}</span>
      </div>
    </div>
    <div v-if="loading">
      <LoadingTable class="min-h-[320px]" />
    </div>
    <div v-if="timeSheets.length === 0 && !loading">
      <EmptyState
        :title="emptyStateTitle"
        :description="emptyStateDescription"
        :icon="ClockIcon"
        class="min-h-[320px] mt-0 flex flex-col justify-center bg-gray-50 rounded-b mb-4"
      />
      <div class="flex justify-between">
        <CopyRecentTimesheetButton
          v-if="isOwnEmployee"
          :date="selectedDay"
          @refresh="emit('refresh')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ClockIcon } from "@heroicons/vue/24/outline"
import { computed } from "vue"
import type { PropType } from "vue"
import { startCase } from "lodash-es"
import { useI18n } from "vue-i18n"
import { getTotalMinutesForDay } from "../util/timeUtils"
import LoadingTable from "@/components/table/LoadingTable.vue"
import EmptyState from "@/components/data/EmptyState.vue"
import { minutesToHours } from "@/modules/common/utils/parseHours"
import { useProjectStore } from "@/modules/projects/store/projectStore"
import { DATE_TIME_FORMAT, formatDate } from "@/modules/common/utils/dateUtils"
import Timesheet = App.Domains.TimeSheets.Models.Timesheet
import Data = API.Data
import CopyRecentTimesheetButton from "@/modules/time/components/CopyRecentTimesheetButton.vue"
import { useAuth } from "@/modules/auth/composables/useAuth"
import TimerButton from "@/modules/time/components/TimerButton.vue"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { can } from "@/plugins/permissionPlugin"
import { useHolidayUtils } from "@/modules/time/composables/useHolidayUtils"
import { isWeekend } from "date-fns";

const props = defineProps({
  selectedDay: {
    type: Date,
    required: true,
  },
  selectedEmployee: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  timeSheets: {
    type: Array as PropType<Data<Timesheet>[]>,
    default: () => [],
  },
})
const emit = defineEmits(['refresh', 'edit'])
const projectStore = useProjectStore()
const settingsStore = useSettingsStore()

const formattedSelectedDate = computed(() => {
  return startCase(formatDate(props.selectedDay, 'EEEE, dd MMMM'))
})

const { userEmployeeId } = useAuth()
const isOwnEmployee = computed(() => {
  return props.selectedEmployee === userEmployeeId.value
})

const { isNationalDay } = useHolidayUtils()
const { t } = useI18n()

const emptyStateTitle = computed(() => {
  if (isWeekend(props.selectedDay)) {
    return t('Whoa there, workaholic!')
  }
  if (isNationalDay(props.selectedDay)) {
    return t('Just a heads-up! This date is a national holiday.')
  }
  return `${t('No time entries for')} ${formattedSelectedDate.value} ${t(' … did work even happen? 🤔')}`
})

const emptyStateDescription = computed(() => {
  if (isWeekend(props.selectedDay)) {
    return t('This date falls on a weekend—are you sure you want to log time? Maybe go outside, touch some grass. 🌿😉')
  }
  if (isNationalDay(props.selectedDay)) {
    return t('Maybe take a break instead of logging time? Just saying. 😉')
  }
  return t('Better log your hours before future-you wonders where the time went! ⏳')
})

function updateWorkedMinutes(time: Data<Timesheet>, workedMinutes: number) {
  time.attributes.worked_minutes = workedMinutes
}

function getProjectName(time: Data<Timesheet>) {
  let name: string = time?.relationships?.project?.attributes?.name
  if (!name) {
    const project = projectStore.getProjectById(time?.attributes?.project_id)
    name = project?.attributes?.name ?? ''
  }
  return name
}

function getTaskName(time: Data<Timesheet>) {
  const { task_id, project_id } = time?.attributes
  let name: string = time?.relationships?.task?.attributes?.name
  if (!name) {
    const task = projectStore.getTaskById(project_id, task_id)
    name = task?.attributes?.name ?? ''
  }
  return name
}
</script>

<style>
.daily-timesheet .el-loading-mask {
  background-color: theme('colors.gray.50') !important;
}
</style>
