<template>
  <div>
    <BaseDataTable
      url="/restify/assets"
      view-url="/assets/{id}/view"
      :url-params="urlParams"
      :columns="columns"
      :add-text="$t('Add asset')"
      :row-height="60"
      :extra-actions="rowActions"
      :delete-extra-confirmation="true"
      :editable="isEditable"
      :actions="actions"
      v-bind="editableTableProps"
      entity="assets"
      dom-layout="normal"
      class="h-full"
      @add="onAdd"
      @grid-init="grid = $event"
      @cell-value-changed="onCellValueChanged"
    >
      <template #attributes.name="{ row }">
        <router-link
          :to="`/assets/${row.id}/view`"
          class="no-underline text-base-content capitalize flex items-center space-x-2"
        >
          {{ row.attributes.name }}
        </router-link>
      </template>
      <template #attributes.status="{ row }">
        <AssetStatus :status="row.attributes.status" />
      </template>
    </BaseDataTable>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import {
  CellValueChangedEvent,
  ColDef,
  GridReadyEvent, ICellEditorParams,
  ValueFormatterParams,
  ValueSetterParams,
} from "@ag-grid-community/core"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"
import { set } from "lodash"
import {
  ExclamationTriangleIcon,
  MinusCircleIcon,
  ShieldCheckIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/vue/24/outline"
import { can } from "@/plugins/permissionPlugin"
import { RowAction } from "@/components/table/tableTypes"
import { ColumnTypes, tableCellEditors } from "@/components/table/cells/tableCellComponents"
import { FilterTypes } from "@/components/table/filters/filterTypes"
import {
  AssetStatus as AssetStatusEnum,
  assetStatusOptions,
  assetTypeOptions,
  getAssetStatusLabel,
  getAssetTypeLabel,
} from "@/modules/assets/util/assetUtils"
import AssetStatus from "@/modules/assets/components/AssetStatus.vue"
import {
  maxLengthValueSetter,
  positiveNumericValueSetter,
  requiredMaxLengthValueSetter,
  updateInlineEntry,
} from "@/components/table/tableUtils"
import { error } from "@/components/common/NotificationPlugin"
import Data = API.Data
import Asset = App.Domains.Assets.Models.Asset
import BaseDataTable from "@/components/table/BaseDataTable.vue"
import { useTableEditing } from "@/components/table/useTableEditing"
import i18n from "@/i18n"
import { useAssetStore } from "@/modules/assets/store/assetStore"

const props = defineProps({
  employeeId: {
    type: String,
  },
})

const urlParams = ref({
  employee_id: props.employeeId,
})

const { t } = useI18n()

const isEditable = computed(() => {
  return can('manageAssets')
})

const defaultMaxLength = 255

const columns = computed<ColDef[]>(() => {
  return [
    {
      headerName: t('Name'),
      field: 'attributes.name',
      type: 'custom',
      minWidth: 120,
      maxWidth: 250,
      editable: isEditable.value,
      valueSetter: (params: ValueSetterParams) => requiredMaxLengthValueSetter(params, defaultMaxLength),
    },
    {
      headerName: t('Employee'),
      field: 'attributes.employee_id',
      type: ColumnTypes.EmployeeLink,
      cellRendererParams: {
        useDataAsLink: false,
        showLink: false,
      },
      minWidth: 150,
      maxWidth: 350,
      initialHide: props.employeeId ? true : !can('manageAssets'),
      filter: props.employeeId ? null : FilterTypes.Employee,
    },
    {
      headerName: t('Type'),
      field: 'attributes.type',
      valueFormatter: (params: ValueFormatterParams) => {
        return getAssetTypeLabel(params.value)
      },
      minWidth: 120,
      maxWidth: 200,
      editable: isEditable.value,
      cellEditor: tableCellEditors.BaseSelectEditor,
      cellEditorParams: {
        options: assetTypeOptions(),
      },
      filter: FilterTypes.AssetType,
      hide: true,
    },
    {
      headerName: t('Status'),
      field: 'attributes.status',
      valueFormatter: (params: ValueFormatterParams) => {
        return getAssetStatusLabel(params.value)
      },
      type: 'custom',
      minWidth: 100,
      editable: isEditable.value,
      cellEditor: tableCellEditors.BaseSelectEditor,
      cellEditorParams: {
        options: assetStatusOptions(),
      },
      filter: FilterTypes.AssetStatus,
    },
    {
      headerName: t('Model'),
      field: 'attributes.model',
      minWidth: 150,
      maxWidth: 250,
      editable: isEditable.value,
      valueSetter: (params: ValueSetterParams) => maxLengthValueSetter(params, defaultMaxLength),
      hide: true,
    },
    {
      headerName: t('Serial Number'),
      field: 'attributes.serial_number',
      minWidth: 120,
      maxWidth: 200,
      hide: true,
      editable: isEditable.value,
      valueSetter: (params: ValueSetterParams) => maxLengthValueSetter(params, defaultMaxLength),
    },
    {
      headerName: t('Supplier'),
      field: 'attributes.supplier',
      minWidth: 100,
      maxWidth: 250,
      hide: true,
      initialHide: !can('manageAssets'),
      editable: isEditable.value,
      valueSetter: (params: ValueSetterParams) => maxLengthValueSetter(params, defaultMaxLength),
    },
    {
      headerName: t('Purchase Value'),
      field: 'attributes.purchase_value',
      type: ColumnTypes.Price,
      minWidth: 140,
      editable: isEditable.value,
      valueSetter: positiveNumericValueSetter,
      initialHide: !can('manageAssets'),
    },
    {
      headerName: t('Acquisition Date'),
      field: 'attributes.acquisition_date',
      type: ColumnTypes.Date,
      minWidth: 180,
      maxWidth: 250,
      filter: FilterTypes.AcquisitionDate,
      editable: isEditable.value,
      cellEditor: 'agDateStringCellEditor',
      hide: true,
    },
  ]
})

const actions = computed(() => {
  const baseActions = []
  baseActions.push('view')
  if (can('manageAssets')) {
    baseActions.push('add')
    baseActions.push('edit')
    baseActions.push('delete')
  }
  return baseActions.join(',')
})

const assetStore = useAssetStore()
const rowActions = computed<RowAction[]>(() => {
  return [
    {
      label: i18n.t('Mark as Maintenance'),
      icon: WrenchScrewdriverIcon,
      action: async (row: Data<Asset>, params: ICellEditorParams) => {
        await assetStore.markAssetAsMaintenance(row)
        setNodeStatus(params, AssetStatusEnum.Maintenance)
      },
      show: (row: Data<Asset>) => {
        return assetStore.canMarkAsMaintenance(row)
      },
    },
    {
      label: i18n.t('Mark as Decommissioned'),
      icon: ExclamationTriangleIcon,
      action: async (row: Data<Asset>, params: ICellEditorParams) => {
        await assetStore.markAssetAsDecommissioned(row)
        setNodeStatus(params, AssetStatusEnum.Decommissioned)
      },
      show: (row: Data<Asset>) => {
        return assetStore.canMarkAsDecommissioned(row)
      },
    },
    {
      label: i18n.t('Mark as Lost'),
      icon: MinusCircleIcon,
      action: async (row: Data<Asset>, params: ICellEditorParams) => {
        await assetStore.markAssetAsLost(row)
        setNodeStatus(params, AssetStatusEnum.Lost)
      },
      show: (row: Data<Asset>) => {
        return assetStore.canMarkAsLost(row)
      },
    },
  ]
})

function setNodeStatus(params: ICellEditorParams, status: string) {
  params.data.attributes.status = status
  params.node.setData(params.data)
}

const router = useRouter()

async function onAdd() {
  await router.push({
    path: '/assets/create',
    query: {
      employee_id: props.employeeId,
    },
  })
}

const grid = ref<GridReadyEvent>()
const { editableTableProps } = useTableEditing(grid)

async function onCellValueChanged(params: CellValueChangedEvent) {
  const field = params?.colDef?.field
  await saveOrUpdateEntry(params)
}

function getRowData(entry: Data<Asset>) {
  return {
    ...entry.attributes,
    id: entry?.id,
  }
}

async function saveOrUpdateEntry(params: CellValueChangedEvent) {
  const row = params.data
  const field = params?.colDef?.field as string
  const oldData = {
    ...params.data,
  }
  set(oldData, field, params.oldValue)

  const entry = { ...row }
  set(entry, field, params.newValue)

  let savedData = params.data
  const requestData = getRowData(entry)
  try {
    params.data.loading = true
    if (!entry.id) {
      return
    }
    savedData = await updateInlineEntry({
      url: `/restify/assets/${entry.id}`,
      method: 'put',
      row: requestData,
      params,
    })
    params.node.setData(params.data)
  } catch (err: any) {
    console.error(err)
    params.node.setData(oldData)
    if (!err.handled) {
      error(t('Failed to save changes'))
    }
  }
  return savedData
}
</script>
