<template>
  <FilterInput
    v-bind="$attrs"
    :data="options"
    value-key="value"
    label-key="label"
    filterable
  />
</template>

<script lang="ts" setup>
import { computed } from "vue"
import FilterInput from "@/components/table/filters/base/FilterInput.vue"
import { assetTypeOptions } from "@/modules/assets/util/assetUtils"

const options = computed(() => {
  return assetTypeOptions()
})
</script>
