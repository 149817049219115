<template>
  <div>
    <div class="flex flex-wrap items-center w-full justify-between">
      <PageTitle
        class="mb-2"
        :show-back="true"
        :loading="currentAssetLoading"
      >
        {{ assetName }}
      </PageTitle>
      <div class="flex flex-1 justify-end items-center space-x-4">
        <AssetStatus :status="currentAsset?.attributes?.status"/>
        <ActionsDropdown
          v-if="hasVisibleActions"
          :actions="assetActions"
        >
          <template #trigger>
            <button
              class="btn btn-outline border border-input flex items-center space-x-3
              hover:bg-white hover:border-transparent hover:text-base-content hover:shadow-lg"
            >
              <span>{{ $t('Actions') }}</span>
              <ChevronDownIcon class="w-4 h-4 text-primary"/>
            </button>
          </template>
        </ActionsDropdown>
      </div>
    </div>

    <BaseContentCard v-if="currentAsset.id" :title="$t('Asset Information')">
      <ContentCardRow :title="$t('Name')">
        <div class="flex space-x-3 items-center text-sm">
          <DocumentTextIcon class="w-4 h-4 text-primary"/>
          <span>{{ currentAsset.attributes.name }}</span>
        </div>
      </ContentCardRow>
      <ContentCardRow :title="$t('Type')">
        <div class="flex space-x-3 items-center text-sm">
          <QueueListIcon class="w-4 h-4 text-primary"/>
          <span>{{ getAssetTypeLabel(currentAsset.attributes.type) }}</span>
        </div>
      </ContentCardRow>
      <ContentCardRow :title="$t('Status')">
        <div class="flex space-x-3 items-center text-sm">
          <AssetStatus :status="currentAsset.attributes.status"/>
        </div>
      </ContentCardRow>
      <ContentCardRow :title="$t('Model')" class="mt-4">
        <div class="flex space-x-3 items-center text-sm">
          <SwatchIcon class="w-4 h-4 text-primary"/>
          <span>{{ currentAsset.attributes.model || '-' }}</span>
        </div>
      </ContentCardRow>
      <ContentCardRow :title="$t('Serial Number')">
        <div class="flex space-x-3 items-center text-sm">
          <HashtagIcon class="w-4 h-4 text-primary"/>
          <span>{{ currentAsset.attributes.serial_number || '-' }}</span>
        </div>
      </ContentCardRow>
      <ContentCardRow v-if="can('manageAssets')" :title="$t('Supplier')">
        <div class="flex space-x-3 items-center text-sm">
          <BuildingStorefrontIcon class="w-4 h-4 text-primary"/>
          <span>{{ currentAsset.attributes.supplier || '-' }}</span>
        </div>
      </ContentCardRow>
    </BaseContentCard>

    <BaseContentCard v-if="currentAsset.id" :title="$t('Who uses the asset')" class="mt-3 relative">
      <ContentCardRow :title="$t('Employee')">
        <EmployeeAvatar
          v-if="currentAsset.attributes.employee_id"
          :data="employeeStore.getEmployeeById(currentAsset.attributes.employee_id)"
        />
        <div v-else class="text-sm">
          {{ $t('Not assigned to an employee') }}
        </div>
      </ContentCardRow>
      <ContentCardRow :title="$t('Assigned By')">
        <EmployeeAvatar
          v-if="currentAsset.attributes.assigned_by"
          :data="employeeStore.getEmployeeById(currentAsset.attributes.assigned_by)"
        />
        <div v-else class="text-sm">
          {{ $t('-') }}
        </div>
      </ContentCardRow>
      <ContentCardRow :title="$t('Assigned At')">
        <div class="flex space-x-3 items-center text-sm">
          <CalendarIcon class="w-4 h-4 text-primary"/>
          <span>{{ $formatDate(currentAsset.attributes.assigned_at) }}</span>
        </div>
      </ContentCardRow>
    </BaseContentCard>
    <BaseContentCard v-if="currentAsset.id && can('manageAssets')" :title="$t('Acquisition Information')"
                     class="mt-3 relative">
      <ContentCardRow :title="$t('Acquisition Date')">
        <div class="flex space-x-3 items-center text-sm">
          <CalendarIcon class="w-4 h-4 text-primary"/>
          <span>{{ $formatDate(currentAsset.attributes.acquisition_date) }}</span>
        </div>
      </ContentCardRow>
      <ContentCardRow :title="$t('Purchase Value')">
        <div class="flex space-x-3 items-center text-sm">
          <BanknotesIcon class="w-4 h-4 text-primary"/>
          <span>{{
              $formatPrice(currentAsset.attributes.purchase_value, { currency: currentAsset.attributes.currency })
            }}</span>
        </div>
      </ContentCardRow>
    </BaseContentCard>

    <BaseContentCard v-if="currentAsset.id" :title="$t('Notes')" class="mt-3 relative">
      <ContentCardRow :title="$t('Notes')">
        <div class="flex space-x-3 items-center text-sm">
          <PencilIcon class="w-4 h-4 text-primary"/>
          <pre
            v-if="currentAsset.attributes.notes"
            class="font-sans prose prose-sm"
            v-html="currentAsset.attributes.notes"
          />
          <span v-else>- -</span>
        </div>
      </ContentCardRow>
    </BaseContentCard>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia"
import {
  BanknotesIcon,
  BuildingStorefrontIcon,
  CalendarIcon,
  ChevronDownIcon,
  DocumentTextIcon,
  ExclamationTriangleIcon,
  HashtagIcon,
  MinusCircleIcon,
  PencilIcon,
  PencilSquareIcon,
  QueueListIcon,
  SwatchIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/vue/24/outline"
import { useRoute, useRouter } from "vue-router"
import { computed } from "vue"
import { ICellEditorParams } from "@ag-grid-community/core"
import { useI18n } from "vue-i18n"
import PageTitle from "@/components/common/PageTitle.vue"
import { useAssetStore } from "@/modules/assets/store/assetStore"
import { AssetStatus as AssetStatusEnum, getAssetTypeLabel } from "@/modules/assets/util/assetUtils"
import AssetStatus from "@/modules/assets/components/AssetStatus.vue"
import EmployeeAvatar from "@/modules/employees/components/EmployeeAvatar.vue"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import ActionsDropdown from "@/components/common/ActionsDropdown.vue"
import { RowAction } from "@/components/table/tableTypes"
import i18n from "@/i18n"
import { can } from "@/plugins/permissionPlugin"
import Data = API.Data;
import Asset = App.Domains.Assets.Models.Asset;

const assetStore = useAssetStore()
const employeeStore = useEmployeeStore()
const { assetName, currentAssetLoading, currentAsset } = storeToRefs(assetStore)
const router = useRouter()

const route = useRoute()

function goToEdit() {
  const path = route.fullPath.replace('view', 'edit')
  router.push({ path })
}

const { t } = useI18n()

const assetActions = computed<RowAction[]>(() => {
  return [
    {
      icon: PencilSquareIcon,
      label: t('Edit'),
      action: async () => {
        await router.push(`/assets/${currentAsset.value.id}/edit`)
      },
      show: () => {
        return can('manageAssets')
      },
    },
    {
      label: i18n.t('Mark as Maintenance'),
      icon: WrenchScrewdriverIcon,
      action: async (row: Data<Asset>) => {
        await assetStore.markAssetAsMaintenance(row)
      },
      show: () => {
        return assetStore.canMarkAsMaintenance(currentAsset.value)
      },
    },
    {
      label: i18n.t('Mark as Decommissioned'),
      icon: ExclamationTriangleIcon,
      action: async (row: Data<Asset>) => {
        await assetStore.markAssetAsDecommissioned(row)
      },
      show: () => {
        return assetStore.canMarkAsDecommissioned(currentAsset.value)
      },
    },
    {
      label: i18n.t('Mark as Lost'),
      icon: MinusCircleIcon,
      action: async (row: Data<Asset>) => {
        await assetStore.markAssetAsLost(row)
      },
      show: () => {
        return assetStore.canMarkAsLost(currentAsset.value)
      },
    },
  ]
})

const hasVisibleActions = computed(() => {
  return assetActions.value.filter((action: RowAction) => action.show()).length > 0
})
</script>
