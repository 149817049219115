<template>
  <div class="flex flex-col">
    <PageTitle :show-back="false" :show-bread-crumbs="false">
      {{ $t($route.name) }}
    </PageTitle>
    <TabLinks v-if="tabs.length > 1" v-model="activeTab" :tabs="tabs" class="mb-4" />
    <AssetsTable :employee-id="userEmployeeId" class="flex-1" />
  </div>
</template>

<script setup lang="ts">
import { useAuth } from "@/modules/auth/composables/useAuth"
import { useFilterTabs } from "@/modules/common/composables/useFilterTabs"
import PageTitle from "@/components/common/PageTitle.vue"
import AssetsTable from "@/modules/assets/components/AssetsTable.vue"
const { userEmployeeId } = useAuth()

const { activeTab, tabs } = useFilterTabs('assets')
</script>

<route lang="yaml">
name: My Assets
</route>
