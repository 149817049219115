import en from 'date-fns/locale/en-US'
import ro from 'date-fns/locale/ro'
import { format, formatDistance, parse, parseISO } from 'date-fns'
import i18n from "@/i18n"

export const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy'
export const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm'
export const API_DATE_FORMAT = 'dd-MM-yyyy'
export const EXCEL_DATE_FORMAT = 'dd/MM/yyyy'
export const API_REPORT_DATE_FORMAT = 'yyyy-MM-dd'
export const CalendarDateFormat = 'yyyy-MM-dd'
export const FriendlyDateFormat = 'do MMMM yyyy'

export const localeMap: any = {
  'en-us': en,
  'en': en,
  'ro-ro': ro,
  'ro': ro,
}

export function getDateFnsLocale(locale: string) {
  return localeMap[locale] || localeMap.en
}
export function formatDate(date: Date | string, dateFormat = DEFAULT_DATE_FORMAT) {
  if (!date) {
    return '- -'
  }

  let dateToFormat: any = date
  if (typeof date === 'string') {
    dateToFormat = parseISO(date)
  }

  if (!dateToFormat.getTime || isNaN(dateToFormat.getTime())) {
    return '- -'
  }
  return format(dateToFormat, dateFormat, { locale: getDateFnsLocale(i18n.locale.value) })
}

export function parseDatePickerDate(date: string) {
  return parse(date, 'yyyy-MM-dd', new Date()).toString()
}

export function removeTimezoneFromDate(date: Date) {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
}
