<template>
  <BaseFormDialog
    v-bind="$attrs"
    :submit-text="$t('Yes, archive')"
    :loading="employeeStore.archiveLoading"
    width="700px"
    @submit="onSubmit"
  >
    <div class="flex justify-center col-span-6 mb-6">
      <IconBox>
        <XCircleIcon class="w-4 h-4 icon-box-icon"/>
      </IconBox>
    </div>
    <div class="flex justify-center col-span-6">
      <div class="text-center flex flex-col items-center">
        <h3 id="modal-headline" class="text-2xl col-span-6 leading-6 font-semibold text-base-content">
          {{ $t('Archive ') }} {{ props.employee?.attributes.name }}?
        </h3>
        <div class="mt-4 max-w-md col-span-6">
          <p class="mt-4 text-sm max-w-md leading-5 text-base-300">
            {{
              $t('After archiving, the employee will no longer be able to access the system. Their data will be kept for reporting purposes')
            }}
          </p>
        </div>
      </div>
    </div>
    <FormKit
      v-model="model.start_date"
      type="datepicker"
      outer-class="col-span-6"
      :label="$t('Working Start Date')"
      :disabled="true"
    />
    <FormKit
      v-model="model.termination_date"
      type="datepicker"
      outer-class="col-span-6"
      :label="$t('Working End Date')"
      :validation="`required|date_after:${startDate}`"
      validation-visibility="dirty"
    />

  </BaseFormDialog>
</template>

<script setup>
import axios from 'axios'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import BaseFormDialog from "@/components/form/BaseFormDialog.vue"
import { error, success } from "@/components/common/NotificationPlugin"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore";
import IconBox from "@/components/common/IconBox.vue";
import { XCircleIcon } from "@heroicons/vue/24/outline";
import { API_DATE_FORMAT, CalendarDateFormat, formatDate } from "@/modules/common/utils/dateUtils";

const props = defineProps({
  employee: {
    type: Object,
    default: () => ({}),
  },
})

const emit = defineEmits(['submit'])

const { t } = useI18n()

const startDate = computed(() => {
  return formatDate(props.employee?.attributes?.start_date, CalendarDateFormat)
})

const model = ref({
  termination_date: props.employee?.attributes?.termination_date,
  start_date: props.employee?.attributes?.start_date,
})

const employeeStore = useEmployeeStore()

async function onSubmit() {
  try {
    if (model.value.termination_date) {
      const termination_date = formatDate(model.value.termination_date, API_DATE_FORMAT)
      await axios.patch(`/restify/employees/${props.employee.id}`, {
        termination_date,
      })
    }
    await employeeStore.archiveEmployee(props.employee)
    await success(t('Employee archived'))
    emit('submit')
  } catch (err) {
    console.log(err)
    if (!err.handled) {
      error(t('Failed to archive employee'))
    }
  }
}
</script>

