<template>
  <div class="flex flex-col">
    <div
      v-if="currentAssetError || currentAssetLoading"
      class="flex-1 flex justify-center items-center h-[50vh]"
    >
      <LoadingTable v-if="currentAssetLoading"/>
      <div v-else class="flex flex-col items-center">
        <IconBox class="mb-4" color="error">
          <ExclamationTriangleIcon class="w-8 h-8"/>
        </IconBox>
        <h2>{{ $t('An error occurred while trying to access the asset information') }}</h2>
      </div>
    </div>
    <RouterView v-else class="flex-1"/>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue"
import { storeToRefs } from "pinia"
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline"
import { useHead } from "@vueuse/head"
import { useRoute } from "vue-router"
import { useI18n } from "vue-i18n"
import { useAssetStore } from "@/modules/assets/store/assetStore";

const { t } = useI18n()

const assetStore = useAssetStore()
const { currentAssetLoading, currentAssetError } = storeToRefs(assetStore)
const route = useRoute()
const id = computed(() => route.params.id as string)

const { assetName } = storeToRefs(assetStore)

useHead({
  title: assetName,
})
watch(() => id.value, async (value: string) => {
  await assetStore.getAsset(value)
})
onMounted(async () => {
  await assetStore.getAsset(id.value)
})
</script>

<route lang="yaml">
name: Asset
redirect:
  name: Asset Details
</route>
