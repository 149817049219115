<template>
  <Badge :status="mappedStatus" :title="title" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { AssetStatus, getAssetStatusLabel } from "@/modules/assets/util/assetUtils"
const props = defineProps({
  status: {
    type: String,
    required: true,
  },
})

const colorMap = {
  [AssetStatus.Available]: 'info',
  [AssetStatus.InUse]: 'primary',
  [AssetStatus.Maintenance]: 'danger',
  [AssetStatus.Decommissioned]: 'danger',
  [AssetStatus.Lost]: 'danger',
}

const mappedStatus = computed(() => {
  return colorMap[props.status]
})

const title = computed(() => {
  return getAssetStatusLabel(props.status)
})
</script>

<style>
</style>
