<template>
  <BaseTooltip
    :content="settingsStore.makeHiddenFieldsVisible ? $t('Hide all sensitive fields ') : $t('Make all sensitive fields visible')">
    <BaseButton
      variant="secondary"
      class="bg-white"
      size="xs"
      v-bind="$attrs"
      @click="settingsStore.makeHiddenFieldsVisible = !settingsStore.makeHiddenFieldsVisible"
    >
      <EyeSlashIcon v-if="settingsStore.makeHiddenFieldsVisible" class="w-4 h-4" />
      <EyeIcon v-else class="w-4 h-4" />
    </BaseButton>
  </BaseTooltip>
</template>

<script setup lang="ts">
import { EyeIcon, EyeSlashIcon } from "@heroicons/vue/24/outline"
import BaseTooltip from "@/components/common/BaseTooltip.vue"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"

const settingsStore = useSettingsStore()
</script>
