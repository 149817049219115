<template>
  <RoleSelect
    ref="select"
    v-model="value"
    v-focus
    class="w-full"
    @update:modelValue="onChangeValue"
  />
</template>

<script lang="ts">
import { useCellEditor } from "@/components/table/cells/editors/useCellEditor"
import RoleSelect from "@/components/formkit/RoleSelect.vue"

export default {
  components: {
    RoleSelect,
  },
  setup(props: any) {
    return useCellEditor(props.params)
  },
}
</script>
