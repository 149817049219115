<template>
  <div>
    <BaseDataTable
      ref="table"
      url="/restify/employees"
      :url-params="urlParams"
      :columns="columns"
      :add-text="$t('Add Member')"
      :row-height="75"
      :extra-actions="rowActions"
      :suppress-cell-focus="true"
      :actions="actions"
      entity="employees"
      class="h-full"
    >
      <template
        v-if="can('manageEmployees')"
        #bulk-actions
      >
        <li>
          <router-link
            class="btn-sm no-underline"
            to="/employees/import"
          >
            <DocumentArrowUpIcon class="w-4 h-4 text-primary" />
            <span class="text-left">{{ t('Import from Excel') }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            class="btn-sm no-underline"
            to="/employees/bulk-edit"
          >
            <TableCellsIcon class="w-4 h-4 text-primary" />
            <span class="text-left">{{ t('Bulk update members') }}</span>
          </router-link>
        </li>
      </template>
      <template #start_date="{ row }">
        <span>
          {{ t('Months count', getMonthsFrom(row.attributes.start_date)) }}
        </span>
      </template>
      <template #header-actions-right>
        <button
          v-if="can('manageEmployees')"
          class="btn btn-sm btn-primary"
          data-test="Add employee"
          @click="onAdd"
        >
          <PlusIcon class="w-4 h-4 mr-1" />
          {{ $t('Add Member') }}
        </button>
      </template>
    </BaseDataTable>

    <GenerateDocumentsModal
      v-if="showGenerateModal"
      v-model="showGenerateModal"
      :entity-id="currentEmployee.id"
      :entity="TemplateEntities.Employee"
      @close="showGenerateModal = false"
      @save="onDocumentGenerated"
    />
    <ArchiveEmployeeDialog
      v-if="showArchiveDialog"
      v-model="showArchiveDialog"
      :employee="employeeToArchive"
      @submit="onEmployeeArchived"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { useRoute, useRouter } from 'vue-router'
import { ColDef, ValueFormatterParams } from "@ag-grid-community/core"
import {
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
  DocumentArrowUpIcon,
  DocumentPlusIcon,
  PaperAirplaneIcon,
  PlusIcon,
  TableCellsIcon,
  XCircleIcon,
} from "@heroicons/vue/24/outline"
import { useI18n } from "vue-i18n"
import { differenceInMonths } from "date-fns"
import { capitalize } from "lodash-es"
import { ColumnTypes } from "@/components/table/cells/tableCellComponents"
import i18n from "@/i18n"
import { EmployeeStatus } from "@/modules/employees/utils/employeeUtils"
import { error, success } from "@/components/common/NotificationPlugin"
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import { $confirm } from "@/components/common/modal/modalPlugin"
import { can } from "@/plugins/permissionPlugin"
import { RowAction } from "@/components/table/tableTypes"
import { FilterTypes } from "@/components/table/filters/filterTypes"
import { useBillingStore } from "@/modules/settings/store/billingStore"
import Employee = App.Domains.Employees.Models.Employee
import Data = API.Data
import { TemplateEntities } from "@/modules/templates/store/templateStore"
import GenerateDocumentsModal from "@/modules/employees/components/GenerateDocumentsModal.vue"
import ArchiveEmployeeDialog from "@/modules/employees/components/ArchiveEmployeeDialog.vue";
import { getContractTypeLabel } from "@/modules/clients/utils/employeeUtils";

const showArchivedEmployees = ref(false)
const billingStore = useBillingStore()

const route = useRoute()

const urlParams = computed(() => {
  return {
    related: 'position,user.invitations,user.roles',
    archived: !!showArchivedEmployees.value,
  }
})

const showGenerateModal = ref(false)
const currentEmployee = ref()

const { t } = useI18n()
const columns = ref<ColDef[]>([
  {
    headerName: t('Name'),
    field: 'attributes.first_name',
    type: ColumnTypes.EmployeeLink,
    minWidth: 200,
  },
  {
    headerName: t('Position'),
    field: 'relationships.position.attributes.name',
    filter: FilterTypes.Position,
    minWidth: 150,
  },
  {
    headerName: t('Email'),
    field: 'attributes.email',
    minWidth: 200,
  },
  {
    headerName: t('Status'),
    field: 'attributes.status',
    type: ColumnTypes.Status,
    minWidth: 120,
    filter: FilterTypes.EmployeeStatus,
  },
  {
    headerName: t('Start Date'),
    field: 'attributes.start_date',
    minWidth: 140,
    type: ColumnTypes.Date,
    hide: true,
    filter: FilterTypes.StartDate,
  },
  {
    headerName: t('Months worked'),
    field: 'start_date',
    type: ColumnTypes.Date,
    hide: true,
    minWidth: 120,
  },
  {
    headerName: t('Country'),
    field: 'attributes.address.country',
    hide: true,
    minWidth: 180,
  },
  {
    headerName: t('City'),
    field: 'attributes.address.city',
    hide: true,
    minWidth: 180,
  },
  {
    headerName: t('Contract Type'),
    field: 'attributes.contract_type',
    hide: true,
    minWidth: 180,
    valueFormatter: (params: ValueFormatterParams) => {
      return getContractTypeLabel(params.value)
    },
  },
  {
    headerName: t('Role'),
    field: 'relationships.user.relationships.roles',
    minWidth: 150,
    hide: true,
    valueFormatter: (params: ValueFormatterParams) => {
      return params.value?.map((role: any) => capitalize(t(role.attributes?.name || ''))).join(', ')
    },
  },
])

const employeeStore = useEmployeeStore()
const employeeToArchive = ref()
const showArchiveDialog = ref(false)

const table = ref()
const actions = computed(() => {
  let baseActions = ['view']

  const isArchived = route.query.archived as any
  const archivedEmployees = isArchived === true || isArchived === 'true'
  if (archivedEmployees) {
    baseActions = []
  }
  if (!archivedEmployees && can('manageEmployees')) {
    baseActions.push('edit')
  }
  return baseActions.join(',')
})

const rowActions = computed<RowAction[]>(() => {
  return [
    {
      label: i18n.t('Send Invite'),
      icon: PaperAirplaneIcon,
      action: async (row: any, params: any) => {
        const confirmed = await $confirm({
          title: t('Send Invitation'),
          description: t(`The employee will receive an email with a link to create an account.`),
          buttonText: t('Send'),
          icon: XCircleIcon,
        })
        if (!confirmed) {
          return
        }

        await employeeStore.sendInvitation({
          employee_id: row.id,
          email: row.attributes.email,
          user_id: row.relationships?.user?.id,
        })
        row.attributes.status = EmployeeStatus.Invited
        params.api!.applyTransaction({
          update: [row],
        })
      },
      show: (row: any) => {
        return row.attributes.status === EmployeeStatus.Draft && can('manageEmployees')
      },
    },
    {
      label: i18n.t('Resend Invite'),
      icon: PaperAirplaneIcon,
      action: async (row: any, params: any) => {
        try {
          await employeeStore.resendInvitation(row)
          row.attributes.status = EmployeeStatus.Invited
          params.api!.applyTransaction({
            update: [row],
          })
        } catch (err: any) {
          if (err.handled) {
            return
          }
          error(t('Could not resend invitation'))
        }
      },
      show: (row: any) => {
        const statuses = [EmployeeStatus.Invited, EmployeeStatus.Cancelled]
        return statuses.includes(row.attributes.status) && can('manageEmployees')
      },
    },
    {
      label: i18n.t('Cancel Invite'),
      icon: XCircleIcon,
      action: async (employee: Data<Employee>, params: any) => {
        await employeeStore.cancelInvitation(employee)
        employee.attributes.status = EmployeeStatus.Cancelled
        params.api!.applyTransaction({
          update: [employee],
        })
      },
      show: (row: any) => {
        return row.attributes.status === EmployeeStatus.Invited && can('manageEmployees')
      },
    },
    {
      label: i18n.t('Generate Documents'),
      icon: DocumentPlusIcon,
      action: async (row: Data<Employee>) => {
        showGenerateModal.value = true
        currentEmployee.value = row
      },
      show: (row) => {
        return can('manageEmployees') && row.attributes.status !== EmployeeStatus.Archived
      },
    },
    {
      label: i18n.t('Archive'),
      icon: ArchiveBoxIcon,
      action: async (employee: Data<Employee>, params: any) => {
        showArchiveDialog.value = true
        employeeToArchive.value = employee
      },
      show: (row: Data<Employee>) => {
        if (row.attributes?.status === EmployeeStatus.Archived) {
          return false
        }
        return can('manageEmployees')
      },
    },
    {
      label: i18n.t('Restore'),
      icon: ArchiveBoxXMarkIcon,
      action: async (employee: Data<Employee>) => {
        const billingStore = useBillingStore()
        if (!billingStore.canAddSeat) {
          await billingStore.showAddSeatsModal()
          return
        }
        const confirmed = await $confirm({
          title: t('Restore'),
          description: t(`After restoring, the employee will be able to access the system again`),
          buttonText: t('Yes, restore'),
          icon: XCircleIcon,
        })
        if (!confirmed) {
          return
        }
        await employeeStore.restoreEmployee(employee)
        await refreshTable()
        await success(t('Employee restored successfully'))
      },
      show: (row: Data<Employee>) => {
        return row.attributes?.status === EmployeeStatus.Archived && can('manageEmployees')
      },
    },
  ]
})

function onEmployeeArchived() {
  showArchiveDialog.value = false
  refreshTable()
}
const router = useRouter()

async function onDocumentGenerated() {
  showGenerateModal.value = false
  const employee = currentEmployee.value?.id
  setTimeout(() => {
    router.push(`/employees/${employee}/documents`)
  }, 500)
}

function getMonthsFrom(date: string) {
  if (!date) {
    return 0
  }
  return differenceInMonths(new Date(), new Date(date))
}

async function onAdd() {
  if (!billingStore.canAddSeat) {
    await billingStore.showAddSeatsModal()
    return
  }
  await router.push('/employees/create')
}

async function refreshTable() {
  await table.value?.refresh()
}
</script>

<route lang="yaml">
name: Employees List
</route>
