<template>
  <HiddenDetails show-button-on-hover>
    <span>
      {{ value }}
    </span>
  </HiddenDetails>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import HiddenDetails from "@/components/common/HiddenDetails.vue"

const props = defineProps({
  params: {
    type: Object,
    default: () => ({}),
  },
})

const value = computed(() => {
  console.log(props.params)
  return props.params.valueFormatted || props.params.value
})
</script>
