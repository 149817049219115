<template>
  <div class="h-full min-h-full mt-4">
    <LoadingTable v-if="loading" />
    <div v-else class="min-h-[400px] flex flex-col items-center justify-center">
      <div class="my-4 flex flex-col items-center justify-center">
        <DownloadButton @click="onDownload">
          {{ $t('Download presence sheet') }}
        </DownloadButton>
      </div>
      <iframe
        :src="officePreviewUrl"
        class="w-full h-full min-h-[500px] flex-1"
        allowfullscreen
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
// @ts-expect-error
import { useI18n } from "vue-i18n"
import { IntervalTypes, ReportTypes, useReportsStore } from "@/modules/reports/store/reportsStore"
import { API_REPORT_DATE_FORMAT, formatDate, FriendlyDateFormat } from "@/modules/common/utils/dateUtils"
import LoadingTable from "@/components/table/LoadingTable.vue"
import { API_URL } from "@/modules/common/config"
import DownloadButton from "@/components/common/buttons/DownloadButton.vue";
import { downloadFileLocally } from "@/modules/common/utils/downloadFileLocally";

const loading = ref(false)

const src = ref('')
const reportStore = useReportsStore()

const { t } = useI18n()
const timestamp = new Date().getTime()

const reportSrc = computed(() => {
  const start_date = formatDate(reportStore.currentReport.from, API_REPORT_DATE_FORMAT)
  const end_date = formatDate(reportStore.currentReport.to, API_REPORT_DATE_FORMAT)
  const token = localStorage.getItem('token')
  return `${API_URL}/restify/employees/getters/export?repositories=all&format=xlsx&range[start_date]=${start_date}&range[end_date]=${end_date}&token=${token}&timestamp=${timestamp}`
})
reportStore.currentReport.intervalType = IntervalTypes.Month
reportStore.currentReport.reportType = ReportTypes.PresenceSheet

const from = computed(() => {
  return formatDate(reportStore.currentReport.from, FriendlyDateFormat)
})

const to = computed(() => {
  return formatDate(reportStore.currentReport.to, FriendlyDateFormat)
})

const officePreviewUrl = computed(() => {
  let url = reportSrc.value
  url = encodeURIComponent(url)
  return `https://view.officeapps.live.com/op/embed.aspx?src=${url}`
})

async function onDownload() {
  window.open(reportSrc.value, '_blank')
}
</script>

<route lang="yaml">
name: Presence sheet report
</route>
