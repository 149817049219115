<template>
  <div class="flex items-center w-full justify-center table-actions-dropdown">
    <ElDropdown
      ref="dropdown"
      :hide-on-click="false"
      trigger="click"
      placement="bottom-end"
    >
      <span class="el-dropdown-link hover:bg-primary/5">
        <EllipsisHorizontalIcon class="w-5 text-primary" />
      </span>
      <template #dropdown>
        <ElDropdownMenu class="min-w-[150px]">
          <ElDropdownItem
            v-for="action in rowActions"
            :key="action.label"
            :class="{
              'dropdown-item-loading relative': actionsLoading[action.label],
            }"
            :data-test="`${action.type}_action`"
            @click.native="triggerAction(action)"
          >
            <div
              :class="{
                [action?.class]: action?.class,
                'opacity-0': actionsLoading[action.label],
              }"
              class="flex space-x-2 items-center"
            >
              <component :is="action.icon" class="w-4 h-4 text-primary" />
              <span class="text-sm">{{ action.label }}</span>
            </div>
            <div
              v-if="actionsLoading[action.label]"
              v-loading="actionsLoading[action.label] && !actionsFinished[action.label]"
              class="absolute flex justify-center items-center inset-0 w-full h-full action-loading"
            >
              <CheckIcon
                v-if="actionsFinished[action.label]"
                class="w-5 h-5 text-primary transition-opacity duration-100"
              />
            </div>
          </ElDropdownItem>
        </ElDropdownMenu>
      </template>
    </ElDropdown>
  </div>
</template>

<script setup lang="ts">
import { CheckIcon, EllipsisHorizontalIcon, EyeIcon, PencilSquareIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { PropType, computed, ref } from "vue"
import axios from "axios"
import { useRouter } from "vue-router"
import { ICellEditorParams } from "@ag-grid-community/core"
import i18n from "@/i18n"
import { $deleteConfirm } from "@/components/common/modal/modalPlugin"
import { error } from "@/components/common/NotificationPlugin"
import { canDeleteRow, TableActions } from "@/components/table/tableUtils"
import { RowAction } from "@/components/table/tableTypes"
import Data = API.Data;
const props = defineProps({
  actions: {
    type: String,
  },
  filterRowActions: {
    type: Function,
  },
  tableProps: {
    type: Object,
    default: () => ({}),
  },
  params: {
    type: Object as PropType<ICellEditorParams>,
    default: () => ({}),
  },
})

const emit = defineEmits(['view', 'edit', 'delete', 'afterDelete'])

const router = useRouter()

const id = computed(() => {
  return props.params?.data?.id
})

const entity = computed(() => {
  return props.tableProps?.entity
})

interface ActionsLoading {
  [key: string]: boolean
}
const actionsLoading = ref<ActionsLoading>({})
const actionsFinished = ref<ActionsLoading>({})
const dropdown = ref()
async function triggerAction(action: RowAction) {
  const exceptionActions = ['view', 'edit', 'delete']
  const isException = exceptionActions.includes(action.label.toLowerCase())
  try {
    if (!isException) {
      actionsLoading.value[action.label] = true
    } else {
      dropdown.value?.handleClose()
    }
    await action.action()
    actionsFinished.value[action.label] = true
    setTimeout(() => {
      actionsFinished.value[action.label] = false
      actionsLoading.value[action.label] = false
      dropdown.value?.handleClose()
    }, 1000)
  } catch (err) {
    dropdown.value?.handleClose()
    actionsLoading.value[action.label] = false
  }
}
const rowActions = computed(() => {
  let defaultActions: RowAction[] = [
    {
      label: i18n.t('View'),
      type: TableActions.View,
      icon: EyeIcon,
      action: async () => {
        emit('view', props.params.data)
        if (props.tableProps.viewUrl) {
          const url = props.tableProps.viewUrl.replace('{id}', id.value)
          await router.push(url)
          return
        }
        await router.push(`/${entity.value}/${id.value}/details`)
      },
      show: () => {
        return props.actions?.includes(TableActions.View)
      },
    },
    {
      label: i18n.t('Edit'),
      type: TableActions.Edit,
      icon: PencilSquareIcon,
      action: async () => {
        let rowData = props.params.data
        if (rowData.id) {
          rowData = await props.params.api?.getRowNode(rowData.id)?.data
        }
        emit('edit', rowData, props.params?.rowIndex)
        if (props.tableProps?.dialogForm) {
          return
        }
        if (props.tableProps.editUrl) {
          const url = props.tableProps.editUrl.replace('{id}', id.value)
          await router.push(url)
          return
        }
        const entityPath = props.tableProps.entityPath || entity.value
        router.push(`/${entityPath}/${id.value}/edit`)
      },
      show: () => {
        return props.actions?.includes(TableActions.Edit)
      },
    },
    {
      label: i18n.t('Delete'),
      type: TableActions.Delete,
      icon: TrashIcon,
      action: async () => {
        emit('delete', props.params.data)
        const confirmed = await $deleteConfirm({
          title: props.tableProps?.deleteTitle || i18n.t('Are you sure ?'),
          description: props.tableProps?.deleteDescription || i18n.t('Are you sure you want to delete this row ? The data will be removed on our servers. This action cannot be undone.'),
          extraConfirmation: props.tableProps.deleteExtraConfirmation,
        })
        const rowId = props.params?.data?.id
        const deleteUrl = `${props.tableProps.url}/${rowId}`
        if (!confirmed) {
          return
        }
        if (!rowId) {
          props.params?.api?.applyTransaction({ remove: [props.params.data] })
          emit('afterDelete', props.params.data)
          return
        }
        try {
          await axios.delete(deleteUrl)
          props.params?.api?.applyTransaction({ remove: [props.params.data] })
          emit('afterDelete', props.params.data)
        } catch (err: any) {
          if (err.handled) {
            return
          }
          error(i18n.t('Could not delete the selected row'))
        }
      },
      show: () => {
        if (props.tableProps.showDeleteFunction) {
          return props.tableProps.showDeleteFunction(props.params.data)
        }
        return props.actions?.includes(TableActions.Delete)
      },
    },
  ]
  defaultActions = defaultActions.filter(action => action.show())
  let extraActions = props.tableProps.extraActions || []

  extraActions = extraActions
    .map((action: RowAction) => {
      return {
        ...action,
        action: async () => {
          if (!action.action) {
            return
          }
          await action.action(props.params.data, props.params)
        },
        show: () => {
          if (!action.show) {
            return true
          }
          return action.show(props.params.data, props.params)
        },
      }
    })
    .filter((action: RowAction) => action.show())

  const mainActions = defaultActions.filter(action => action.type !== TableActions.Delete)
  const destructiveActions = defaultActions.filter(action => action.type === TableActions.Delete)
  const allActions = [
    ...mainActions,
    ...extraActions,
    ...destructiveActions,
  ]
  if (props.filterRowActions) {
    return allActions.filter(action => props.filterRowActions(action, props.params.data))
  } else {
    return allActions.filter(filterAction)
  }
})

function filterAction(action: RowAction) {
  const { meta } = props.params?.data || {}
  if (!meta) {
    return true
  }
  if (action?.type === TableActions.Edit) {
    return meta.authorizedToUpdate
  } else if (action?.type === TableActions.Delete) {
    return canDeleteRow(props.params.data)
  } else if (action?.type === TableActions.View) {
    return meta.authorizedToShow
  }
  return true
}
</script>

<style>
.dropdown-item-loading {
  --el-mask-color: theme('colors.gray.50');
  --el-loading-spinner-size: 24px;
  @apply bg-gray-50;
}
</style>
