import axios from "axios"
import { ColDef, GridApi, ValueSetterParams } from "@ag-grid-community/core"
import { LicenseManager } from "@ag-grid-enterprise/core"
import { UnwrapRef } from "vue"
import Data = API.Data
import { set } from "lodash"
import { error } from "@/components/common/NotificationPlugin"
import i18n from "@/i18n"

export enum TableActions {
  Add = 'add',
  BulkActions = 'bulk',
  Export = 'export',
  Refresh = 'refresh',
  View = 'view',
  Edit = 'edit',
  Delete = 'delete',
  AfterDelete = 'afterDelete',
  Archive = 'archive',
}

export function hasAction(actions: string, action: TableActions) {
  return actions?.includes(action)
}

export function getSortProp(params: any, gridApi: UnwrapRef<GridApi | null>) {
  gridApi?.showLoadingOverlay()
  const columns = params.columnApi.getColumnState()
  const sortOption: any = {}
  columns.forEach((column: any) => {
    if (column.sort) {
      sortOption.prop = column.colId
      sortOption.order = column.sort
    }
  })

  let sortProp = null
  if (sortOption.order === 'asc') {
    sortProp = sortOption.prop
  }
  if (sortOption.order === 'desc') {
    sortProp = `-${sortOption.prop}`
  }
  sortProp = sortProp?.replace('attributes.', '')

  return sortProp
}

export function isColumnSortable(column: ColDef, gridContext: any) {
  let field = column.field || ''
  field = field.replace('attributes.', '')
  let sortables = gridContext.filterOptions.sortables || []

  if (!sortables.length) {
    return column.sortable || false
  }

  sortables = sortables.map(s => s.column)
  return sortables.includes(field)
}

export function canDeleteRow(data: Data<any>) {
  const { meta, attributes } = data || {}
  if (attributes?.is_used) {
    return false
  }
  return meta?.authorizedToDelete
}

export async function saveInlineEntry({ row, params, url, transformData }: any) {
  if (params.data.creating) {
    // We are in the process of creating this row already, therefore delaying the save
    return params.data
  }
  let rowData = params.data
  try {
    params.data.creating = true
    params.node.setData(params.data)
    delete row.id
    let { data } = await axios.post(url, row)
    if (transformData) {
      data = transformData(data)
    }
    rowData = mergeRowData(params.data, data)
    const prevData = params.data
    params.data = rowData
    params.data._localId = prevData._localId
    params.node.setData(params.data)
  } finally {
    params.data.creating = false
    params.node.setData(params.data)
  }
  return rowData
}

export async function updateInlineEntry({ row, params, url, method, transformData }: any) {
  try {
    const requestMethod: string = method || 'put'
    params.data.updating = true
    params.node.setData(params.data)
    // @ts-expect-error
    let { data } = await axios[requestMethod](url, row)
    if (transformData) {
      data = transformData(data)
    }
    const rowData = mergeRowData(params.data, data)
    params.data = rowData
    params.data.updating = false
    params.node.setData(rowData)
    return rowData
  } finally {
    params.data.updating = false
  }
}

function mergeRowData(localData: any, apiData: any) {
  const data = apiData?.attributes ? apiData.attributes : apiData
  return {
    ...localData,
    ...(data || {}),
  }
}

export function setLicenseKey() {
  let licenseKey = import.meta.env.VITE_AG_GRID_LICENSE_KEY

  if (!licenseKey) {
    console.error('No Ag Grid license key configured (VITE_AG_GRID_LICENSE_KEY environment variable)')
    return
  }

  if (licenseKey.startsWith('\"')) {
    // Remove quotes since some tools like Forge don't allow setting env vars with spaces
    try {
      licenseKey = JSON.parse(licenseKey)
    } catch (err) {
      console.warn('Error parsing the ag grid license key', err)
    }
  }
  LicenseManager.setLicenseKey(licenseKey)
}

export function requiredValueSetter(params: ValueSetterParams, defaultValue: any, validator?: (value: any) => boolean) {
  const value = params.newValue
  const field = params.column?.colId
  let valid = false
  if (!field) {
    return false
  }
  if (value !== undefined && value !== '' && value !== null) {
    set(params.data, field, params.newValue)
    valid = true
  }

  if (defaultValue !== undefined && !value) {
    set(params.data, field, defaultValue)
    valid = true
  }

  if (validator) {
    valid = validator(params.newValue)
  }

  if (!valid && validator) {
    set(params.data, field, params.oldValue)
  }

  return valid
}

export function normalValueSetter(params: ValueSetterParams, validator?: (value: any) => boolean) {
  const field = params.column?.colId
  let valid = false
  if (!field) {
    return false
  }
  set(params.data, field, params.newValue)

  if (validator) {
    valid = validator(params.newValue)
  }

  if (!valid && validator) {
    set(params.data, field, params.oldValue)
  }

  return valid
}

export function requiredMaxLengthValueSetter(params: ValueSetterParams, maxLength: number) {
  return requiredValueSetter(params, undefined, maxLengthValidator(maxLength))
}

export function maxLengthValueSetter(params: ValueSetterParams, maxLength: number) {
  return normalValueSetter(params, maxLengthValidator(maxLength))
}

export function booleanValueSetter(params: ValueSetterParams) {
  let value = params.newValue
  const field = params.column.colId
  if (value === 'false' || value === '0') {
    value = false
  } else if (value === 'true' || value === '1') {
    value = true
  } else {
    value = !!value
  }

  set(params.data, field, value)
  return true
}

export function positiveNumericValueSetter(params: ValueSetterParams) {
  const value = params.newValue
  const field = params.column.colId
  const numericValue = +value
  if (isNaN(numericValue) || numericValue < 0) {
    error(i18n.t('Value must be a positive number'))
    return false
  }

  set(params.data, field, value)
  return true
}

export function emailValueSetter(params: ValueSetterParams) {
  const value = params.newValue
  const field = params.column.colId
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  if (value.length > 255) {
    error(i18n.t('Value can not be longer than 255 characters'))
    return false
  }
  if (!emailRegex.test(value)) {
    error(i18n.t('Invalid email format'))
    return false
  }

  set(params.data, field, value)
  return true
}

export function maxLengthValidator(maxLength: number) {
  return (value: any) => {
    if (!value) {
      return true
    }
    if (value.length > maxLength) {
      error(i18n.t('Value can not be longer than {maxLength} characters', { maxLength }))
      return false
    }
    return true
  }
}
