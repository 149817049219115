<template>
  <div
    :class="{
      'bg-white border border-base-200 rounded-sm p-4 md:p-6 w-full mb-1': !isDialog,
    }"
  >
    <slot name="content">
      <div
        :class="{
          'max-w-5xl': !isDialog && !containerClass,
          [containerClass]: containerClass,
        }"
        class="grid grid-cols-6 gap-x-4"
      >
        <ElCollapse v-if="collapsible" class="col-span-6 collapse-item !border-none">
          <ElCollapseItem :name="titleId">
            <template #title>
              <h5
                v-if="title && !isDialog || $slots.title"
                :id="titleId"
                :class="titleClass"
                class="col-span-6 text-base"
              >
                <slot name="title">
                  {{ title }}
                </slot>
              </h5>
            </template>
            <slot />
          </ElCollapseItem>
        </ElCollapse>
        <template v-else>
          <h5
            v-if="title && !isDialog || $slots.title"
            :id="titleId"
            :class="titleClass"
            class="col-span-6"
          >
            <slot name="title">
              {{ title }}
            </slot>
          </h5>
          <slot />
        </template>
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { snakeCase } from "lodash-es"

const props = defineProps({
  title: {
    type: String,
  },
  isDialog: {
    type: Boolean,
    default: false,
  },
  titleClass: {
    type: String,
  },
  containerClass: {
    type: String,
  },
  collapsible: {
    type: Boolean,
    default: false,
  },
})

const titleId = computed(() => {
  return snakeCase(props.title)
})
</script>
<style lang="scss">
.collapse-item .el-collapse-item__header {
  @apply border-none;
  .el-icon svg {
    @apply w-4 h-4 min-w-[16px];
  }
}
</style>
