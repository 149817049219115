import Status from './Status.vue'
import DocumentStatus from './DocumentStatus.vue'
import HolidayRequestStatus from './HolidayRequestStatus.vue'
import ExpenseStatus from './ExpenseStatus.vue'
import YesNoStatus from './YesNoStatus.vue'
import FileLink from './FileLink.vue'
import EmployeeLink from './EmployeeLink.vue'
import ProjectLink from './ProjectLink.vue'
import ClientLink from './ClientLink.vue'
import EmployeeTagsCell from './EmployeeTagsCell.vue'
import FormattedPrice from './FormattedPrice.vue'
import PositionLink from "@/components/table/cells/PositionLink.vue"
import HiddenValue from "@/components/table/cells/HiddenValue.vue"
import FormattedDate from "@/components/table/cells/FormattedDate.vue"
import EvaluationTypeLink from "@/components/table/cells/EvaluationTypeLink.vue"
import BaseSelectEditor from "@/components/table/cells/editors/BaseSelectEditor.vue"
import ExpenseCategorySelectCellEditor from "@/components/table/cells/editors/ExpenseCategorySelectCellEditor.vue"
import CurrencySelectEditor from "@/components/table/cells/editors/CurrencySelectEditor.vue"
import ReceiptEditor from "@/components/table/cells/editors/ReceiptEditor.vue"
import EmployeeCellEditor from "@/components/table/cells/editors/EmployeeCellEditor.vue"
import PositionCellEditor from "@/components/table/cells/editors/PositionSelectCellEditor.vue"
import DepartmentCellEditor from "@/components/table/cells/editors/DepartmentSelectCellEditor.vue"
import RoleCellEditor from "@/components/table/cells/editors/RoleCellEditor.vue"
import CountryCellEditor from "@/components/table/cells/editors/CountrySelectCellEditor.vue"
import HtmlCellEditor from "@/components/table/cells/editors/HtmlCellEditor.vue"
import ProjectCellEditor from "@/components/table/cells/editors/ProjectCellEditor.vue"
import TimezoneCellEditor from "@/components/table/cells/editors/TimezoneCellEditor.vue";
import LevelCellEditor from "@/components/table/cells/editors/LevelCellEditor.vue";

export const tableCellComponents: any = {
  Status,
  DocumentStatus,
  YesNoStatus,
  FileLink,
  EmployeeLink,
  ProjectLink,
  ClientLink,
  HiddenValue,
  PositionLink,
  EmployeeTagsCell,
  FormattedDate,
  FormattedPrice,
  ExpenseStatus,
  HolidayRequestStatus,
  EvaluationTypeLink,
}

export const tableCellEditors = {
  ReceiptEditor,
  HtmlCellEditor,
  BaseSelectEditor,
  ProjectCellEditor,
  PositionCellEditor,
  DepartmentCellEditor,
  LevelCellEditor,
  RoleCellEditor,
  CountryCellEditor,
  TimezoneCellEditor,
  EmployeeCellEditor,
  CurrencySelectEditor,
  ExpenseCategorySelectCellEditor,
}

export enum ColumnTypes {
  Custom = 'custom',
  Price = 'FormattedPrice',
  Status = 'Status',
  DocumentStatus = 'DocumentStatus',
  HolidayRequestStatus = 'HolidayRequestStatus',
  ExpenseStatus = 'ExpenseStatus',
  YesNoStatus = 'YesNoStatus',
  FileLink = 'FileLink',
  EmployeeLink = 'EmployeeLink',
  ProjectLink = 'ProjectLink',
  ClientLink = 'ClientLink',
  PositionLink = 'PositionLink',
  HiddenValue = 'HiddenValue',
  EvaluationTypeLink = 'EvaluationTypeLink',
  EmployeeTags = 'EmployeeTagsCell',
  Date = 'FormattedDate',
}
