<template>
  <div class="invoice-form">
    <PageTitle>
      <template v-if="!isEdit">
        {{ t('New invoice for', { clientName: client?.attributes?.company_name }) }}
      </template>
      <template v-else>
        {{ t('Invoice {id}', { id: invoice?.attributes?.invoice_number }) }}
      </template>
    </PageTitle>
    <BaseFormCard
      :submit-text="isEdit ? t('Update Invoice') : t('Create Invoice')"
      class="mt-4"
      @submit="createOrUpdateInvoice"
      @cancel="onCancel"
    >
      <WarningAlert v-if="isPaid" class="mb-4">
        {{ t('This invoice was already marked paid.') }}
      </WarningAlert>
      <FormCardSection :title="t('Invoice details')" container-class="max-w-6xl">
        <div class="col-span-6 flex flex-col md:flex-row justify-between">
          <div class="w-full space-y-4 mt-4">
            <div class="w-full space-y-4">
              <div class="flex flex-wrap md:space-x-4 items-center">
                <div class="max-w-sm w-full">
                  <FormKit
                    v-model="model.invoice_number"
                    :label="t('Invoice ID')"
                    validation="required"
                    type="text"
                  />
                </div>
                <div
                  v-if="invoiceStore.lastInvoiceNumber"
                  class="text-gray-500 text-sm"
                >
                  <span class="mr-2">{{ t('Last used:') }}</span>
                  <span>{{ invoiceStore.lastInvoiceNumber }}</span>
                </div>
              </div>
              <div class="max-w-sm">
                <FormKit
                  v-model="model.issue_date"
                  :label="t('Issue date')"
                  validation="required"
                  type="customDatePicker"
                  @update:modelValue="onDueDateValueChange(model.due_date_value)"
                />
              </div>
            </div>
            <div class="flex flex-col lg:flex-row md:space-x-2">
              <div class="max-w-sm w-full">
                <FormKit
                  v-model="model.due_date_value"
                  :label="t('Due date')"
                  :options="dueDateOptions"
                  validation="required"
                  type="customSelect"
                  @update:modelValue="onDueDateValueChange"
                />
              </div>
              <div class="flex w-full">
                <div class="min-w-[150px] lg:min-w-0 mr-2" />
                <div class="max-w-[240px] mt-2 lg:mt-0">
                  <FormKit
                    v-if="model.due_date_value === 'custom'"
                    v-model="model.due_date"
                    :placeholder="t('Due date')"
                    type="customDatePicker"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="w-full space-y-4 mt-4 items-end max-w-md">
            <FormKit
              v-model="model.client_id"
              :label="t('Invoice for')"
              validation="required"
              type="clientSelect"
              @update:modelValue="onClientChange"
            />
            <FormKit
              v-model="model.project_id"
              :client-id="model.client_id"
              :label="t('Project')"
              :select-default="true"
              clearable
              type="projectSelect"
            />
            <FormKit
              v-model="model.currency"
              type="currencySelect"
              :label="t('Currency')"
              :placeholder="t('EUR')"
              outer-class="col-span-6 md:col-span-1"
            />
            <FormKit
              v-model="model.tax"
              :label="t('Tax')"
              :placeholder="t('Tax %')"
              type="number"
              validation="min:0|max:99"
            />
          </div>
        </div>
        <div class="col-span-6 mt-6">
          <FormKit
            v-model="model.title"
            :label="t('Subject')"
            validation="required"
            type="text"
          />
        </div>
      </FormCardSection>
      <FormCardSection :title="t('Invoice entries')" container-class="max-w-6xl">
        <InvoiceLineItems
          ref="items"
          :invoice="model"
          :line-items="initialLineItems"
          :invoice_id="invoice?.id as string"
          class="col-span-6"
        />
      </FormCardSection>
      <FormCardSection :title="t('Notes')" container-class="max-w-6xl">
        <FormKit
          v-model="model.notes"
          :label="t('Notes (optional)')"
          type="textarea"
          outer-class="col-span-6"
        />
      </FormCardSection>
    </BaseFormCard>
  </div>
</template>

<script lang="ts" setup>
import { PropType, computed, nextTick, onMounted, provide, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import addDays from 'date-fns/addDays'
import differenceInDays from 'date-fns/differenceInDays'
import { useClientStore } from '@/modules/clients/store/clientStore'
import FormCardSection from '@/components/form/FormCardSection.vue'
import { Invoice, LineItem } from '@/modules/invoices/types/invoiceTypes'
import InvoiceLineItems from '@/modules/invoices/components/InvoiceLineItems.vue'
import { useInvoiceStore } from '@/modules/invoices/store/invoiceStore'
import { API_REPORT_DATE_FORMAT, formatDate } from '@/modules/common/utils/dateUtils'
import Data = API.Data
import { success } from '@/components/common/NotificationPlugin'
import Client = App.Domains.Clients.Models.Client
import InvoiceStatusComponent from "@/modules/invoices/components/InvoiceStatus.vue"
import { InvoiceStatus } from "@/modules/invoices/types/invoicEnums"
import WarningAlert from "@/components/common/WarningAlert.vue"
import { getDueDateOptions } from "@/modules/invoices/utils/invoiceUtils"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"
import { SettingKeys } from "@/modules/auth/types/enums"
const props = defineProps({
  isEdit: {
    type: Boolean,
    default: false,
  },
  invoice: {
    type: Object as PropType<Data<Invoice>>,
    default: () => ({}),
  },
})
const { t } = useI18n()
const route = useRoute()
const settingsStore = useSettingsStore()
const defaultDueDate = settingsStore.getSetting(SettingKeys.InvoiceDueDays)

const model = ref({
  title: settingsStore.getSetting(SettingKeys.InvoiceSubject) || '',
  invoice_number: '',
  currency: undefined as string | undefined,
  issue_date: getIssueDate(),
  due_date: getIssueDate(),
  due_date_value: defaultDueDate as number | string,
  client_id: route.query.client_id,
  project_id: route.query.project_id,
  notes: settingsStore.getSetting(SettingKeys.InvoiceNotes) || undefined,
  subtotal: 0,
  tax: settingsStore.getSetting(SettingKeys.InvoiceTax) || undefined,
  total: 0,
})

onDueDateValueChange(defaultDueDate as number)

const currency = computed(() => model.value.currency)
const invoiceStore = useInvoiceStore()
provide('currency', currency)

const initialLineItems = computed(() => {
  const lineItems = props.invoice?.relationships?.lineItems || []
  if (!lineItems.length && route.query.tracked_time) {
    return invoiceStore.getInvoiceEntriesFromTimesheets(invoiceStore.timesheetEntriesForInvoice, route.query.group)
  }
  return lineItems.map((item: Data<LineItem>) => {
    return {
      id: item.id,
      ...item.attributes,
    }
  })
})

const dueDateOptions = computed(() => {
  return getDueDateOptions()
})

function getIssueDate() {
  let queryDate = new Date()
  if (route.query.to) {
    queryDate = new Date(route.query.to)
  }
  return queryDate
}

function onDueDateValueChange(value: number | string) {
  value = +value
  if (!isNaN(value)) {
    model.value.due_date = addDays(model.value.issue_date, value)
  }
}

const clientStore = useClientStore()
const client = computed<Data<Client>>(() => {
  return clientStore.getClientById(model.value.client_id as string)
})

async function onClientChange() {
  await nextTick()
  model.value.currency = client.value?.attributes?.prefered_currency as string
}

const router = useRouter()

const items = ref()

async function createOrUpdateInvoice() {
  const lineItems = items.value?.lineItems || []
  const subtotal = items.value?.subTotal
  const total = items.value?.totalAmount
  const from_address = {}
  const to_address = {
    ...(client.value?.attributes.company_address || {}),
  }
  const issue_date = formatDate(model.value.issue_date, API_REPORT_DATE_FORMAT)
  const due_date = formatDate(model.value.due_date, API_REPORT_DATE_FORMAT)
  const data = {
    invoice: {
      ...model.value,
      to_address,
      from_address,
      issue_date,
      due_date,
      subtotal,
      total,
    },
    lineItems,
  }
  if (props.invoice.id) {
    await invoiceStore.updateInvoice(data)
    success(t('Invoice updated'))
    await router.push(`/invoices/${props.invoice.id}/details`)
  } else {
    const invoice = await invoiceStore.createInvoice(data)
    success(t('Invoice created successfully'))
    await router.push(`/invoices/${invoice.id}/details`)
  }
}

const invoiceStatus = computed(() => {
  return props.invoice?.attributes?.status
})

const isPaid = computed(() => {
  return invoiceStatus.value === InvoiceStatus.Paid
})

function onCancel() {
  router.go(-1)
}

watch(() => props.invoice, (value: Data<Invoice>) => {
  if (!value?.id) {
    return
  }
  // @ts-expect-error
  const issue_date = new Date(value.attributes.issue_date)
  const due_date = new Date(value.attributes.due_date)
  const daysDiff = differenceInDays(due_date, issue_date)
  let due_date_value = dueDateOptions.value.find(item => item.value === daysDiff)?.value
  if (due_date_value === undefined) {
    due_date_value = 'custom'
  }
  // @ts-expect-error
  model.value = {
    ...model.value,
    ...value.attributes,
    issue_date,
    due_date,
    due_date_value,
  }
}, { immediate: true })

onMounted(() => {
  invoiceStore.getLastInvoice()
})

watch(() => invoiceStore.nextInvoiceNumber, (value) => {
  if (props.isEdit) {
    return
  }
  model.value.invoice_number = value
}, { immediate: true })
</script>

<style lang="scss">
.invoice-form {
  .formkit-outer-container:not([data-family="button"]),
  .form-control {
    @apply w-full;
  }

  .formkit-outer-container > div {
    @apply flex space-x-2;
  }

  .formkit-outer-container label {
    min-width: 150px;
    @apply p-0;
  }
}
</style>
