import { defineStore } from 'pinia'
import axios from "axios"
import i18n from "@/i18n"
import Cache from '@/modules/common/Cache'
import Data = API.Data
import Asset = App.Domains.Assets.Models.Asset
import { $confirm } from "@/components/common/modal/modalPlugin"
import { ExclamationCircleIcon } from "@heroicons/vue/24/outline"
import { AssetStatus } from "@/modules/assets/util/assetUtils"
import { can } from "@/plugins/permissionPlugin"

export const useAssetStore = defineStore('assets', {
  state: () => {
    return {
      currentAsset: {} as Data<Asset>,
      currentAssetLoading: false as boolean,
      currentAssetError: null as any,
    }
  },
  actions: {
    async getAsset(id: string) {
      if (!id) {
        return
      }
      try {
        this.currentAssetLoading = true
        const { data } = await Cache.getRequest(`/restify/assets/${id}`)
        this.currentAsset = data
        this.currentAssetError = null
      } catch (err: any) {
        this.currentAssetError = err
      } finally {
        this.currentAssetLoading = false
      }
    },

    async createAsset(data: any) {
      return await axios.post(`/restify/assets`, data)
    },

    async updateAsset(id: string, data: any) {
      await axios.post(`/restify/assets/${id}`, data)
      return await this.getAsset(id)
    },

    async deleteAsset(id: string) {
      await axios.delete(`/restify/assets/${id}`)
    },

    canMarkAsMaintenance(data: Data<Asset>) {
      const { status } = data?.attributes || {}
      return can('manageAssets') && ![AssetStatus.Maintenance, AssetStatus.Decommissioned, AssetStatus.Lost].includes(status)
    },

    async markAssetAsMaintenance(data: Data<Asset>) {
      let notes = ''
      const confirmed = await $confirm({
        title: i18n.t('Mark asset as maintenance'),
        description: i18n.t('Are you sure you want to mark this asset as maintenance?'),
        hasInput: true,
        inputLabel: i18n.t('Notes'),
        inputType: 'textarea',
        icon: ExclamationCircleIcon,
        buttonText: i18n.t('Mark as maintenance'),
        onResolve: async ({ extraInputText }) => {
          notes = extraInputText
        },
      })
      if (!confirmed) {
        return
      }
      await axios.post(`/restify/assets/${data.id}/actions?action=mark-asset-as-maintenance-restify-action`, {
        notes,
        expected_return_date: null,
      })
      data.attributes.status = AssetStatus.Maintenance
      return await this.getAsset(data.id)
    },

    canMarkAsDecommissioned(data: Data<Asset>) {
      const { status } = data?.attributes || {}
      return can('manageAssets') && ![AssetStatus.Decommissioned, AssetStatus.Lost].includes(status)
    },

    async markAssetAsDecommissioned(data: Data<Asset>) {
      const confirmed = await $confirm({
        title: i18n.t('Mark asset as decommissioned'),
        description: i18n.t('Are you sure you want to mark this asset as decommissioned?'),
        icon: ExclamationCircleIcon,
        buttonText: i18n.t('Mark as decommissioned'),
      })
      if (!confirmed) {
        return
      }
      await axios.post(`/restify/assets/${data.id}/actions?action=mark-asset-as-decommissioned-restify-action`)
      data.attributes.status = AssetStatus.Decommissioned
      return await this.getAsset(data.id)
    },

    canMarkAsLost(data: Data<Asset>) {
      const { status } = data?.attributes || {}
      return can('manageAssets') && ![AssetStatus.Lost].includes(status)
    },

    async markAssetAsLost(data: Data<Asset>) {
      const confirmed = await $confirm({
        title: i18n.t('Mark asset as lost'),
        description: i18n.t('Are you sure you want to mark this asset as lost?'),
        icon: ExclamationCircleIcon,
        buttonText: i18n.t('Mark as lost'),
      })
      if (!confirmed) {
        return
      }
      await axios.post(`/restify/assets/${data.id}/actions?action=mark-asset-as-lost-restify-action`)
      data.attributes.status = AssetStatus.Lost
      return await this.getAsset(data.id)
    },

  },
  getters: {
    assetName(state): string {
      const { name } = state.currentAsset?.attributes || {}
      return name || ''
    },
  },
})
