<template>
  <div class="flex flex-col">
    <template v-if="!isEditPage">
      <div
        class="flex items-end w-full justify-between"
      >
        <PageTitle
          :loading="pageLoading"
          :show-bread-crumbs="false"
        >
          {{ projectName }}
        </PageTitle>
        <EditButton
          v-if="$can('manageProjects')"
          @click="goToEdit"
        />
      </div>
      <div v-if="tabs.length">
        <TabLinks v-model="activeTab" :tabs="tabs" />
      </div>
      <div v-else class="mb-4" />
    </template>
    <div
      v-if="pageLoading"
      class="flex-1 flex justify-center items-center h-[50vh]"
    >
      <LoadingTable v-if="pageLoading" />
      <div v-else class="flex flex-col items-center">
        <IconBox class="mb-4" color="error">
          <ExclamationTriangleIcon class="w-8 h-8" />
        </IconBox>
        <h2>{{ $t('An error occurred while trying to access the project information') }}</h2>
      </div>
    </div>
    <RouterView v-else class="flex-1" />
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {
  CalendarDaysIcon,
  CheckBadgeIcon,
  ExclamationTriangleIcon,
  InboxIcon, ReceiptPercentIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/vue/24/outline'
import { storeToRefs } from 'pinia'
import { useHead } from '@vueuse/head'
import { useI18n } from "vue-i18n"
import TabLinks from '@/components/tabs/TabLinks.vue'
import EditButton from '@/components/common/buttons/EditButton.vue'
import { can, isModuleVisible } from '@/plugins/permissionPlugin'
import { useProjectStore } from '@/modules/projects/store/projectStore'
import { ModuleKeys } from "@/modules/auth/types/enums"
import { isSpecialCompany } from "@/modules/common/config";

const props = defineProps({
  projectId: {
    type: String,
  },
})
const activeTab = ref('Details')
const projectStore = useProjectStore()

const route = useRoute()

const {
  currentProjectName: projectName,
  currentProject,
  currentProjectLoading,
} = storeToRefs(projectStore)

const id = computed(() => props.projectId as string)

const pageLoading = computed(() => {
  return currentProjectLoading.value && currentProject.value.id?.toString() !== id.value
})
const { t } = useI18n()
const tabs = computed(() => {
  const basePath = `/projects/${id.value}`
  const tabs: any[] = [
    {
      label: t('Details'),
      icon: InboxIcon,
      path: `${basePath}/details`,
      show: true,
      permission: can('manageProjects'),
    },
    {
      label: t('Calendar'),
      icon: CalendarDaysIcon,
      path: `${basePath}/calendar`,
      show: true,
      permission: true,
    },
    {
      label: t('Tasks'),
      icon: CheckBadgeIcon,
      path: `${basePath}/tasks`,
      show: true,
      permission: can('manageProjects'),
    },
    {
      label: t('Capacity'),
      icon: UsersIcon,
      path: `${basePath}/team`,
      show: true,
      permission: can('manageProjects'),
    },
    {
      label: t('Expenses'),
      icon: ReceiptPercentIcon,
      path: `${basePath}/expenses`,
      show: true,
      permission: (can('manageExpenses') || can('manageOwnExpenses')) && isModuleVisible(ModuleKeys.Expenses),
    },
  ]
  return tabs.filter(tab => tab.show !== false && tab.permission === true)
})

const isEditPage = computed(() => {
  return route.path.endsWith('edit')
})

const pageTitle = computed(() => {
  if (!projectName.value) {
    return activeTab.value
  }
  return `${activeTab.value} - ${projectName.value}`
})

useHead({
  title: pageTitle,
})
watch(() => id.value, async (value: string) => {
  if (!value) {
    return
  }
  await projectStore.getProject(value)
}, {
  immediate: true,
})
onUnmounted(() => {
  projectStore.resetCurrentProject()
})

const router = useRouter()

async function goToEdit() {
  await router.push(`/projects/${id.value}/edit`)
}
</script>
