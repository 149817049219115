<template>
  <FilterInput
    v-bind="$attrs"
    :data="options"
    value-key="attributes.name"
    label-key="attributes.heading"
    filterable
  />
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia"
import { computed } from "vue"
import { useI18n } from "vue-i18n"
import FilterInput from "@/components/table/filters/base/FilterInput.vue"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"

const { t } = useI18n()
const settingsStore = useSettingsStore()
const { contractTypes } = storeToRefs(settingsStore)

const options = computed(() => {
  return contractTypes.value.map((contractType) => {
    contractType.attributes.heading = t(contractType.attributes.heading)
    return contractType
  })
})
</script>
