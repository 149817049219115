<template>
  <FilterInput
    v-bind="$attrs"
    :data="departments"
    value-key="id"
    label-key="attributes.name"
    filterable
  />
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia"
import FilterInput from "@/components/table/filters/base/FilterInput.vue"
import { useSettingsStore } from "@/modules/settings/store/settingsStore"

const settingsStore = useSettingsStore()
const { departments } = storeToRefs(settingsStore)
</script>
