<template>
  <FilterInput
    v-bind="$attrs"
    :data="statuses"
    :item-component="YesNoStatus"
    value-key="value"
    label-key="label"
  />
</template>

<script lang="ts" setup>
import { ref } from "vue"
import { useI18n } from "vue-i18n"
import FilterInput from "@/components/table/filters/base/FilterInput.vue"
import YesNoStatus from "@/components/table/cells/YesNoStatus.vue"

const { t } = useI18n()

const statuses = ref([
  {
    value: true,
    label: t('Yes'),
  },
  {
    value: false,
    label: t('No'),
  },
])
</script>
