<template>
  <div class="overflow-x-auto w-full flex space-x-4 mt-10">
    <div v-for="node in data" :key="node.key">
      <OrganizationChart :value="node" collapsible>
        <template #person="{ node }">
          <div class="flex flex-col">
            <div class="flex flex-col items-center">
              <EmployeeLink
                :params="{ data: node.data, showPosition: true }"
              />
              <div v-if="isPartTime(node.data)" class="text-base-300 items-start font-medium text-xs self-start ml-8">
                {{ $t('Part time') }}
              </div>
            </div>
          </div>
        </template>
      </OrganizationChart>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import OrganizationChart from 'primevue/organizationchart'

import { useEmployeeStore } from "@/modules/employees/store/employeeStore"
import Employee = App.Domains.Employees.Models.Employee
import Data = API.Data
import EmployeeLink from "@/components/table/cells/EmployeeLink.vue"
import { orderBy } from "lodash-es"

const employeeStore = useEmployeeStore()

function buildEmployeeTree(employees: Data<Employee>[]) {
  // Create a map to hold employees by their id for quick access
  const employeeMap = new Map()

  // Populate the map with employee data
  employees.forEach((employee: Data<Employee>, index) => {
    employeeMap.set(employee.id, {
      key: `${employee.id}_${index}`,
      type: 'person',
      data: employee,
      children: [],
    })
  })

  // Root of the tree
  const tree: any = []

  // Build the tree by linking employees to their managers
  employees.forEach((employee) => {
    if (employee.attributes.manager_employee_id) {
      const manager = employeeMap.get(employee.attributes.manager_employee_id)
      if (manager) {
        manager.children.push(employeeMap.get(employee.id))
      }
    } else {
      tree.push(employeeMap.get(employee.id)) // Add top-level employees to the tree
    }
  })

  const filteredData = tree.filter((node: any) => node.children.length > 0 || node.data.attributes.manager_employee_id !== null)
  return orderBy(filteredData, (node: any) => node.children.length, 'desc')
}
const data = computed(() => {
  return buildEmployeeTree(employeeStore.allEmployees)
})

function isPartTime(data: Data<Employee>) {
  const fullTimeAllocation = 40
  return data?.attributes?.weekly_hours_capacity < fullTimeAllocation
}
const selection = ref({})
</script>

<style>
</style>
