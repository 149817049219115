import { defineStore } from 'pinia'
import axios from "axios"
import i18n from "@/i18n"
import Cache from '@/modules/common/Cache'
import { formatDate } from "@/modules/common/utils/dateUtils"
import Data = API.Data
import { error, success } from "@/components/common/NotificationPlugin"
import { can } from "@/plugins/permissionPlugin"
import { $confirm } from "@/components/common/modal/modalPlugin"
import { CreditCardIcon, ExclamationCircleIcon } from "@heroicons/vue/24/outline"
import { useAuth } from "@/modules/auth/composables/useAuth"
import { downloadFileLocally } from "@/modules/common/utils/downloadFileLocally";

export const CreateMode = {
  Manual: 'manual',
  Import: 'import',
}

export function isExpensePending(bundle: Data<any>): boolean {
  const { rejected_at, approved_at } = bundle?.attributes || {}
  return rejected_at === null && approved_at === null
}

export function canApproveExpense(bundle: Data<any>): boolean {
  const { approved_at } = bundle?.attributes || {}
  return approved_at === null
}

export function canMarkAsPaid(bundle: Data<any>): boolean {
  const { paid_at } = bundle?.attributes || {}
  return paid_at === null
}

export const useExpenseStore = defineStore('expense', {
  state: () => {
    return {
      currentExpense: {} as Data<any>,
      currentExpenseLoading: false as boolean,
      currentExpenseError: null as any,
    }
  },
  actions: {
    async getExpense(id: string) {
      if (!id) {
        return
      }
      try {
        this.currentExpenseLoading = true
        const { data } = await Cache.getRequest(`/restify/expense-bundles/${id}`, {
          params: {
            related: 'employee,expenses',
          },
        })
        this.currentExpense = data
        this.currentExpense.attributes.expenses = data.relationships.expenses
        this.currentExpenseError = null
      } catch (err: any) {
        this.currentExpenseError = err
      } finally {
        this.currentExpenseLoading = false
      }
    },

    async createExpense(data: any) {
      return await axios.post(`/restify/expense-bundles`, data)
    },

    async createExpenseFromFiles(data: any) {
      if (!Array.isArray(data.files) || data.files?.length === 0) {
        error(i18n.t('No files selected'))
        return
      }
      const formData = new FormData()
      data.files.forEach((file: File) => {
        formData.append('files[]', file)
      })
      return await axios.post(`/restify/expense-bundles/actions?action=create-from-files`, formData)
    },

    async addFilesToExpenseBundle(data: any) {
      if (!Array.isArray(data.files) || data.files?.length === 0) {
        error(i18n.t('No files selected'))
        return
      }
      const formData = new FormData()
      data.files.forEach((file: File) => {
        formData.append('files[]', file)
      })
      return await axios.post(`/restify/expense-bundles/${data.id}/actions?action=add-from-files`, formData)
    },

    async updateExpense(data: any) {
      await axios.put(`/restify/expense-bundles/${data.id}`, data)
      return await this.getExpense(data.id)
    },
    canEditExpenseBundle(data: any) {
      return isExpensePending(data)
    },
    canApproveExpenseBundle(data: any) {
      return canApproveExpense(data) && can('manageExpenses')
    },
    canRejectExpenseBundle(data: any) {
      return isExpensePending(data) && can('manageExpenses')
    },
    canMarkExpenseBundleAsPaid(data: any) {
      return canMarkAsPaid(data) && can('manageExpenses')
    },
    async downloadExpenseBundle(row: Data<any>) {
      const { userEmail } = useAuth()
      await axios.post(`/restify/expense-bundles/${row.id}/actions?action=download-expense-bundle`, {
        email: userEmail.value,
      })
      success(i18n.t('An email with the expense bundle has been sent to {email}', { email: userEmail.value }))
    },
    async downloadExpenseBundleAsPDF(row: Data<any>) {
      const res = await axios.post(`/restify/expense-bundles/${row.id}/actions?action=download-expense-report`, {}, {
        responseType: 'blob',
      })
      downloadFileLocally(res, `${row.attributes.title} Report.docx`)
    },
    async approveExpenseBundle(row: Data<any>) {
      await axios.post(`/restify/expense-bundles/${row.id}/actions?action=approve`, {})
      row.attributes.approved_at = new Date().toISOString()
      success(i18n.t('Expense bundle approved.'))
      Cache.removeForEntity('expense-bundles')
    },
    async rejectExpenseBundle(row: Data<any>) {
      let status_explanation = ''
      const confirmed = await $confirm({
        title: i18n.t('Reject Expense bundle?'),
        description: i18n.t('When you reject an expense bundle, the employee will be notified via email.'),
        hasInput: true,
        inputLabel: i18n.t('Reject reason'),
        inputType: 'textarea',
        icon: ExclamationCircleIcon,
        buttonText: i18n.t('Reject'),
        onResolve: async ({ extraInputText }) => {
          status_explanation = extraInputText
        },
      })
      if (!confirmed) {
        return
      }
      await axios.post(`/restify/expense-bundles/${row.id}/actions?action=reject`, {
        status_explanation,
      })
      row.attributes.rejected_at = new Date().toISOString()
      success(i18n.t('Expense bundle was rejected.'))
      Cache.removeForEntity('expense-bundles')
    },
    async markExpenseBundleAsPaid(row: Data<any>) {
      let status_explanation = ''
      const confirmed = await $confirm({
        title: i18n.t('Mark expense bundle as paid?'),
        description: i18n.t('When you mark an expense bundle as paid, the employee will be notified via email.'),
        hasInput: true,
        inputLabel: i18n.t('Details'),
        inputType: 'textarea',
        icon: CreditCardIcon,
        buttonType: 'primary',
        buttonText: i18n.t('Mark as paid'),
        onResolve: async ({ extraInputText }) => {
          status_explanation = extraInputText
        },
      })
      if (!confirmed) {
        return
      }
      await axios.post(`/restify/expense-bundles/${row.id}/actions?action=mark-as-paid`, {
        status_explanation,
      })
      row.attributes.paid_at = new Date().toISOString()
      success(i18n.t('Expense bundle was marked as paid.'))
      Cache.removeForEntity('expense-bundles')
    },
    async approveExpense(row: any) {
      await axios.post(`/restify/expenses/${row.id}/actions?action=approve`, {})
      row.approved_at = new Date().toISOString()
      success(i18n.t('Expense approved.'))
      Cache.removeForEntity('expenses')
    },
    async rejectExpense(row: any) {
      let status_explanation = ''
      const confirmed = await $confirm({
        title: i18n.t('Reject Expense?'),
        description: i18n.t('When you reject an expense, the status will be updated to rejected.'),
        hasInput: true,
        inputLabel: i18n.t('Reject reason'),
        inputType: 'textarea',
        icon: ExclamationCircleIcon,
        buttonText: i18n.t('Reject'),
        onResolve: async ({ extraInputText }) => {
          status_explanation = extraInputText
        },
      })
      if (!confirmed) {
        return
      }
      await axios.post(`/restify/expenses/${row.id}/actions?action=reject`, {
        status_explanation,
      })
      row.rejected_at = new Date().toISOString()
      success(i18n.t('Expense was rejected.'))
      Cache.removeForEntity('expenses')
    },
    async markExpenseAsPaid(row: any) {
      let status_explanation = ''
      const confirmed = await $confirm({
        title: i18n.t('Mark expense as paid?'),
        description: i18n.t('When you mark an expense as paid, the status will be updated to paid.'),
        hasInput: true,
        inputLabel: i18n.t('Details'),
        inputType: 'textarea',
        icon: CreditCardIcon,
        buttonType: 'primary',
        buttonText: i18n.t('Mark as paid'),
        onResolve: async ({ extraInputText }) => {
          status_explanation = extraInputText
        },
      })
      if (!confirmed) {
        return
      }
      await axios.post(`/restify/expenses/${row.id}/actions?action=mark-as-paid`, {
        status_explanation,
      })
      row.paid_at = new Date().toISOString()
      success(i18n.t('Expense was marked as paid.'))
      Cache.removeForEntity('expenses')
    },
  },
  getters: {
    expenseName(state) {
      const { start_date, end_date, title } = state.currentExpense?.attributes || {}
      return `${title} (${formatDate(start_date)} - ${formatDate(end_date)})`
    },
  },
})
