<template>
  <AssetForm :data="currentAsset" :is-edit="true"/>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia"
import AssetForm from "@/modules/assets/components/AssetForm.vue"
import { useAssetStore } from "@/modules/assets/store/assetStore"

const assetStore = useAssetStore()
const { currentAsset } = storeToRefs(assetStore)
</script>

<route lang="yaml">
name: Asset Edit
</route>
