import i18n from "@/i18n"

export const assetTypeOptions = () => {
  return [
    {
      value: 'electronic',
      label: i18n.t('Electronics'),
    },
    {
      value: 'furniture',
      label: i18n.t('Furniture'),
    },
    {
      value: 'book',
      label: i18n.t('Books'),
    },
    {
      value: 'office',
      label: i18n.t('Office Supplies'),
    },
    {
      value: 'software',
      label: i18n.t('Software'),
    },
    {
      value: 'subscription',
      label: i18n.t('Subscription'),
    },
    {
      value: 'other',
      label: i18n.t('Other'),
    },
  ]
}

export const AssetStatus = {
  Available: 'available',
  InUse: 'in_use',
  Maintenance: 'maintenance',
  Decommissioned: 'decommissioned',
  Lost: 'lost',
}

export const assetStatusOptions = () => {
  return [
    {
      value: AssetStatus.Available,
      label: i18n.t('Available'),
    },
    {
      value: AssetStatus.InUse,
      label: i18n.t('In Use'),
    },
    {
      value: AssetStatus.Maintenance,
      label: i18n.t('Maintenance'),
    },
    {
      value: AssetStatus.Decommissioned,
      label: i18n.t('Decommissioned'),
    },
    {
      value: AssetStatus.Lost,
      label: i18n.t('Lost'),
    },
  ]
}

export function getAssetTypeLabel(value: string) {
  const option = assetTypeOptions().find(option => option.value === value)
  return option ? option.label : value
}

export function getAssetStatusLabel(value: string) {
  const option = assetStatusOptions().find(option => option.value === value)
  return option ? option.label : value
}
