<template>
  <EmployeeForm
    :employee="currentEmployee"
    :loading="currentEmployeeLoading"
    :is-profile-form="true"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia"
import EmployeeForm from '@/modules/employees/components/EmployeeForm.vue'
import { useEmployeeStore } from "@/modules/employees/store/employeeStore"

const employeeStore = useEmployeeStore()
const { currentEmployee, currentEmployeeLoading } = storeToRefs(employeeStore)
</script>

<route lang="yaml">
name: Employee Profile Edit
</route>
