<template>
  <div class="mt-6">
    <div class="grid grid-cols-2 gap-4 pb-10">
      <BirthdayReminderCard class="col-span-2"/>
      <FeaturedNewsCard class="col-span-2"/>
      <AssignedOnboardingTasks class="col-span-2"/>
      <EventsCalendar class="col-span-2" />
      <!-- Will be enabled later -->
      <CompanyNewsCard class="col-span-2 md:col-span-1" />
      <MyActivityCard class="col-span-2 md:col-span-1" />
    </div>
    <WelcomeDialog
      v-model="showWelcomeDialog"
    />
  </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAuth } from '@/modules/auth/composables/useAuth'
import MyActivityCard from '@/modules/dashboard/components/MyActivityCard.vue'
import CompanyNewsCard from '@/modules/dashboard/components/CompanyNewsCard.vue'
import EventsCalendar from '@/modules/dashboard/components/calendar/EventsCalendar.vue'
import FeaturedNewsCard from "@/modules/dashboard/components/FeaturedNewsCard.vue";
import AssignedOnboardingTasks from "@/modules/employees/components/AssignedOnboardingTasks.vue";
import BirthdayReminderCard from "@/modules/dashboard/components/BirthdayReminderCard.vue";

const WelcomeDialog = defineAsyncComponent(() =>
  import('@/modules/dashboard/components/WelcomeDialog.vue'),
)
const { can } = useAuth()

const route = useRoute()
const router = useRouter()
const showWelcomeDialog = ref(!!route.query.welcome)

function onCloseDialog() {
  router.replace({ query: { welcome: null } })
}

watch(() => showWelcomeDialog.value, (value) => {
  if (!value) {
    onCloseDialog()
  }
})
</script>

<route lang="yaml">
name: Dashboard
</route>
